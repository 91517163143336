import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ActorAvatar from "../ActorAvatar";
import DotMenu from "../DotMenu";
import { Clear, Edit, Warning } from "@mui/icons-material";
import AutocompleteSearchField from "../AutocompleteSearchField";
import ActorAutocompleteOption from "../ActorAutocompleteOption";
import { actorPersonTypes, actorSearch } from "../../utils/actorSearch";
import { useApi } from "../../utils/Api";
import { useState } from "react";
import { validateEmailRecipient } from "../actor-permits/validation/ActorValidator";
import PersonCrudDialog from "../actors/crud/PersonCrudDialog";
import Preloader from "../Preloader";
import EditEmailPreviewDialog from "./EditEmailPreviewDialog";
import { useSnackbar } from "notistack";

export default function GroupEmailDialog({
  title,
  recipients: recipientsProp,
  getRecipientEmailPreview,
  sendRecipientEmail,
  defaultOptions,
  newActorParentActorId,
  onClose,
  onCancel,
  overrideOnSend,
  overrideSendText,
  overrideCancelText,
}) {
  const [recipients, setRecipients] = useState(recipientsProp);
  const [recipientToCrudIndex, setRecipientToCrudIndex] = useState(null);
  const [emailPreviews, setEmailPreviews] = useState({});
  const [editEmailPreviewActorId, setEditEmailPreviewActorId] = useState(null);
  const [isLoadingActorId, setIsLoadingActorId] = useState(false);

  const api = useApi();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function compareContacts(lhs, rhs) {
    const lhsCompanyConnections = lhs.parentActors?.filter(
      (parent) => parent.parentActor.actorId === newActorParentActorId
    );
    const lhsIsConnectedToCompany =
      !!lhsCompanyConnections && lhsCompanyConnections.length > 0;
    const lhsIsContactToCompany =
      lhsIsConnectedToCompany &&
      lhsCompanyConnections.some((parent) => parent.actorType === 3);
    const rhsCompanyConnections = rhs.parentActors?.filter(
      (parent) => parent.parentActor.actorId === newActorParentActorId
    );
    const rhsIsConnectedToCompany =
      !!rhsCompanyConnections && rhsCompanyConnections.length > 0;
    const rhsIsContactToCompany =
      rhsIsConnectedToCompany &&
      rhsCompanyConnections.some((parent) => parent.actorType === 3);
    if (
      (lhsIsContactToCompany && !rhsIsContactToCompany) ||
      (lhsIsConnectedToCompany && !rhsIsConnectedToCompany)
    ) {
      return -1;
    }
    if (
      (rhsIsContactToCompany && !lhsIsContactToCompany) ||
      (rhsIsConnectedToCompany && !lhsIsConnectedToCompany)
    ) {
      return 1;
    }
    // Use the order from backend in this case
    return 0;
  }

  async function personSearch(searchText) {
    const foundActors = await actorSearch(
      searchText,
      actorPersonTypes,
      ["ActorName", "OrgNo", "ExternalActorNo"],
      50
    );
    if (foundActors) {
      return foundActors
        .sort(compareContacts)
        .filter((actor) => !recipients.some((d) => d.actorId === actor.actorId))
        .map((actor) => ({
          actorId: actor.actorId,
          name: actor.actorName,
          orgNo: actor.orgNo,
          externalActorNo: actor.externalActorNo,
          email: actor.email,
          profilePictureUrl: actor.profilePictureUrl,
          phoneNumber: actor.phoneNumbers?.find((ph) => ph.type === 2)
            ?.phoneNumber,
          companies: actor.parentActors
            ?.filter(
              (x, index, allParentActors) =>
                x.parentActor.actorId !== actor.actorId &&
                x.parentActor.actorId > 0 &&
                allParentActors.findIndex(
                  (y) => y.parentActor.actorId === x.parentActor.actorId
                ) === index
            )
            .map((x) => ({
              actorId: x.parentActor.actorId,
              name: x.parentActor.actorName,
            })),
        }));
    }
  }

  async function handleRecipientChanged(recipient) {
    if (!recipient) {
      // This happens if the user presses the "Enter" key,
      // rather than selecting the "Add" from the list
      return;
    }

    const isExistingActor = recipient.actorId > 0;
    if (isExistingActor) {
      const validationError = validateEmailRecipient(recipient);
      recipient = { ...recipient, error: validationError };
    }

    const recipientsCopy = [...recipients, recipient];
    setRecipients(recipientsCopy);

    // Automatically open the PersonCrud to edit the new actor
    if (!isExistingActor) {
      setRecipientToCrudIndex(recipientsCopy.length - 1);
    }
  }

  function handleRecipientCrudSave(recipient, recipientIndex) {
    if (!recipient || !recipient.actorId) {
      throw new Error("Missing data");
    }
    recipient = {
      ...recipient,
      name: recipient.actorName,
      email: recipient.actorEmail,
      companies: recipient.childActorGroups
        ? recipient.childActorGroups.map((x) => ({ actorId: x.parentActorId }))
        : null,
    };
    const validationError = validateEmailRecipient(recipient);
    recipient = { ...recipient, error: validationError };
    const recipientsCopy = [...recipients];
    recipientsCopy[recipientIndex] = recipient;
    setRecipients(recipientsCopy);
  }

  function handleRemoveRecipient(index) {
    const recipientsCopy = [...recipients];
    recipientsCopy.splice(index, 1);
    setRecipients(recipientsCopy);
  }

  async function handleEditPreview(recipient) {
    if (!(recipient.actorId in emailPreviews)) {
      setIsLoadingActorId(recipient.actorId);
      const response = await getRecipientEmailPreview(recipient.actorId);
      if (!!response) {
        setEmailPreviews((prev) => ({
          ...prev,
          [recipient.actorId]: response,
        }));
        setEditEmailPreviewActorId(recipient.actorId);
      }
      setIsLoadingActorId(null);
    } else {
      setEditEmailPreviewActorId(recipient.actorId);
    }
  }

  async function handleClickSend() {
    if (overrideOnSend) {
      const emails = recipients.map((r) => {
        const emailPreview = emailPreviews[r.actorId];
        return {
          receiverActorId: r.actorId,
          subject: emailPreview?.subject,
          htmlBody: emailPreview?.htmlBody,
        };
      });
      await overrideOnSend(emails);
    } else {
      await handleSendAllEmails();
    }
  }

  async function handleSendAllEmails() {
    const results = [];
    for (const recipient of recipients) {
      const emailPreview = emailPreviews[recipient.actorId];
      const response = await sendRecipientEmail(
        recipient.actorId,
        emailPreview?.subject,
        emailPreview?.htmlBody
      );
      results.push({ actorId: recipient.actorId, response });
    }

    const failedResults = results.filter((r) => !r.response.isSuccessful);
    if (failedResults.length > 0) {
      setRecipients((prev) =>
        prev
          .filter((p) => failedResults.some((r) => r.actorId === p.actorId))
          .map((p) => ({
            ...p,
            error: Translate.get(
              failedResults.find((r) => r.actorId === p.actorId).response
                .errorMessageTranslationKey
            ),
          }))
      );
    } else {
      const successMessage = enqueueSnackbar(
        Translate.get("SendEmailsSuccess"),
        {
          variant: "success",
          autoHideDuration: 6000,
          onClick: () => closeSnackbar(successMessage),
        }
      );
      onClose();
    }
  }

  function getDotMenuActions({ recipient, onRemoveRecipient, onCrudPerson }) {
    return [
      {
        icon: <Edit />,
        text: Translate.get("EditPersonalInfo"),
        onClick: onCrudPerson,
      },
      ...(getRecipientEmailPreview
        ? [
            {
              icon: <Edit />,
              text: Translate.get("EditMessage"),
              onClick: () => handleEditPreview(recipient),
            },
          ]
        : []),
      {
        icon: <Clear />,
        text: Translate.get("Delete"),
        onClick: onRemoveRecipient,
      },
    ];
  }

  function getParentActorId() {
    if (
      !!recipients[recipientToCrudIndex]?.companies &&
      recipients[recipientToCrudIndex].companies.length > 0
    ) {
      const currentCompany = recipients[recipientToCrudIndex].companies.find(
        (c) => c.actorId === newActorParentActorId
      );
      return currentCompany ? currentCompany.actorId : newActorParentActorId;
    } else {
      return newActorParentActorId;
    }
  }

  const showRecipientCrud = recipientToCrudIndex != null;

  return (
    <>
      {editEmailPreviewActorId && (
        <EditEmailPreviewDialog
          {...emailPreviews[editEmailPreviewActorId]}
          onOk={(updatedPreview) => {
            setEmailPreviews((prev) => ({
              ...prev,
              [editEmailPreviewActorId]: updatedPreview,
            }));
            setEditEmailPreviewActorId(null);
          }}
          onClose={() => setEditEmailPreviewActorId(null)}
        />
      )}
      {showRecipientCrud && (
        <PersonCrudDialog
          open={showRecipientCrud}
          personCrudProps={{
            name: recipients[recipientToCrudIndex]?.name ?? "",
            onClose: () => setRecipientToCrudIndex(null),
            onActorSaved: (actor) =>
              handleRecipientCrudSave(actor, recipientToCrudIndex),
            actorId: recipients[recipientToCrudIndex]?.actorId,
            parentActorId: getParentActorId(),
            actorRole: "contact",
            onCancel: () => setRecipientToCrudIndex(null),
          }}
        />
      )}
      <Dialog open={true} className="smallCourseDialog" onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <Stack spacing={3}>
            <AutocompleteSearchField
              placeholder={Translate.get("AddReceiver")}
              label={Translate.get("AddReceiver")}
              value={""}
              onValueChange={handleRecipientChanged}
              getOptionLabel={(option) => option?.name ?? ""}
              renderOption={(option) => (
                <ActorAutocompleteOption
                  actorName={option?.name}
                  orgNo={option?.orgNo}
                  email={option?.email}
                  companies={option?.companies?.map((x) => x.name)}
                  parentActors={option?.parentActors}
                  externalActorNo={option?.externalActorNo}
                />
              )}
              keyPropName="actorId"
              requireSelection={true}
              search={personSearch}
              createNewValue={(text) => ({
                name: text,
              })}
              defaultOptions={defaultOptions}
            />

            <div className="smallHeader">{`${Translate.get(
              "EmailReceivers"
            )} (${recipients.length})`}</div>
            {recipients.map((recipient, i) => (
              <Stack key={i} direction="column" spacing={1}>
                <Box display="flex" justifyContent="space-between" gap="10px">
                  <ActorAvatar
                    name={recipient.name}
                    email={recipient.email}
                    profilePictureUrl={recipient.profilePictureUrl}
                  />
                  {isLoadingActorId === recipient.actorId ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      sx={{ marginTop: "8px" }}
                    />
                  ) : (
                    <DotMenu
                      content={getDotMenuActions({
                        recipient,
                        onRemoveRecipient: () => handleRemoveRecipient(i),
                        onCrudPerson: () => setRecipientToCrudIndex(i),
                      })}
                      horizontal
                    />
                  )}
                </Box>
                {recipient.error && (
                  <Stack
                    key={i}
                    direction="row"
                    paddingLeft="8px"
                    className="smallRedWarning"
                  >
                    <Warning />
                    <Box>{recipient.error}</Box>
                  </Stack>
                )}
              </Stack>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonWithSpinner variant="text" onClick={onCancel ?? onClose}>
            {overrideCancelText ?? Translate.get("Cancel")}
          </ButtonWithSpinner>
          <ButtonWithSpinner
            variant="contained"
            disabled={
              !recipients ||
              recipients.length === 0 ||
              recipients.some((r) => r.error)
            }
            onClick={handleClickSend}
          >
            {overrideSendText ?? Translate.get("Send")}
          </ButtonWithSpinner>
        </DialogActions>
      </Dialog>
    </>
  );
}
