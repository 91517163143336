import { useState } from "react";
import Preloader from "../components/Preloader";
import OrdersGrid from "../components/orders/OrdersGrid";
import { useCallback } from "react";
import { Card } from "@mui/material";
import Translate from "../utils/Translate";
import SpeedMenu from "../components/SpeedMenu";
import AddIcon from "@mui/icons-material/Add";
import OrderFormBase from "../components/orders/OrderFormBase";
import { accessKeys, hasAccess } from "../utils/userAccess";

export default function OrdersPage(props) {
  const [isLoading, setIsLoading] = useState(true);

  const finishedLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const speedMenuActions = [];
  speedMenuActions.push({
    icon: <AddIcon />,
    text: Translate.get("CreateOrder"),
    customClass: hasAccess(accessKeys.isMASystem)
      ? "bigCourseDialog"
      : "mediumCourseDialog",
    component: <OrderFormBase />,
  });

  return (
    <>
      {isLoading && <Preloader />}
      <SpeedMenu content={speedMenuActions} />
      <div className="stdFlexRow">
        <h2>{Translate.get("Orders")}</h2>
      </div>
      <Card>
        <OrdersGrid onLoaded={finishedLoading} />
      </Card>
    </>
  );
}
