import { useState } from "react";
import Translate from "../../utils/Translate";
import YesOrNoDialog from "../YesOrNoDialog";
import GroupEmailDialog from "../emails/GroupEmailDialog";

export default function OrderSaveQuestionDialog({
  company,
  saveCapabilities,
  addOrUpdateOrder,
  onSaved,
  onSaveFailed,
  onCancelSave,
  getInitialOrderConfirmationRecipients,
  getOrderConfirmationDefaultOptions,
  getOrderConfirmationEmailPreview,
}) {
  const [showStep0] = useState(
    saveCapabilities.canAutoPick || saveCapabilities.canAutoFinish
  );
  const [step, setStep] = useState(showStep0 ? 0 : 1);
  const [useSaveCapabilities, setUseSaveCapabilities] = useState(false);

  async function handleCompleteDialog(emails, useSaveCapabilitiesArg) {
    // We do not have more fine grained questions. If they answer yes we will assume
    // that the question is formulated in such a way as to affirm all available capabilities
    const saved = await addOrUpdateOrder(
      useSaveCapabilitiesArg ? saveCapabilities.canAutoPick : false,
      useSaveCapabilitiesArg ? saveCapabilities.canAutoFinish : false,
      emails
    );
    if (saved) {
      await onSaved();
    } else {
      onSaveFailed();
    }
  }

  switch (step) {
    case 0:
      return (
        <YesOrNoDialog
          open={true}
          title={Translate.get("SaveOrder")}
          text={Translate.get(saveCapabilities.questionTranslationKey)}
          onNo={async () => {
            if (saveCapabilities?.questionRequireYes) {
              // Question requires yes to proceed, so no means cancel
              onCancelSave();
            } else {
              if (saveCapabilities.autoShowOrderConfirmationDialog) {
                setStep(1);
                setUseSaveCapabilities(false);
              } else {
                await handleCompleteDialog(null, false);
              }
            }
          }}
          onYes={async () => {
            if (saveCapabilities.autoShowOrderConfirmationDialog) {
              setStep(1);
              setUseSaveCapabilities(true);
            } else {
              await handleCompleteDialog(null, true);
            }
          }}
          noText={
            saveCapabilities?.questionRequireYes
              ? Translate.get("Cancel")
              : Translate.get("OnlySave")
          }
          yesText={Translate.get("Yes")}
          showCancelButton={!saveCapabilities?.questionRequireYes}
          onCancel={onCancelSave}
        />
      );
    case 1:
      return (
        <GroupEmailDialog
          title={`${Translate.get("Send")} ${Translate.get(
            "OrderConfirmation"
          ).toLowerCase()}`}
          recipients={getInitialOrderConfirmationRecipients()}
          defaultOptions={getOrderConfirmationDefaultOptions()}
          newActorParentActorId={company.actorId}
          getRecipientEmailPreview={getOrderConfirmationEmailPreview}
          onClose={() => (showStep0 ? setStep(0) : onCancelSave())}
          onCancel={async () =>
            await handleCompleteDialog(null, useSaveCapabilities)
          }
          overrideOnSend={async (emails) =>
            await handleCompleteDialog(emails, useSaveCapabilities)
          }
          overrideSendText={Translate.get("SaveOrderAndSendConfirmation")}
          overrideCancelText={Translate.get("SaveOrderAndSkipConfirmation")}
        />
      );
  }
}
