import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Translate from "../../utils/Translate";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { useCallback, useEffect, useState } from "react";
import { useApi } from "../../utils/Api";

export default function FreightDialog({
  isFirstTime,
  onCancel,
  onSave,
  freightId: propFreightId,
  weight: propWeight,
  fixedPrice: propFixedPrice,
}) {
  const [freights, setFreights] = useState([]);
  const [selectedFreightId, setSelectedFreightId] = useState(propFreightId);
  const [weight, setWeight] = useState(propWeight);
  const [fixedPrice, setFixedPrice] = useState(propFixedPrice);
  const api = useApi();

  useEffect(() => {
    async function loadFreights() {
      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}orders/freights`,
        false,
        "GET",
        (response) => response && Array.isArray(response)
      );
      if (response && Array.isArray(response)) {
        setFreights(response);
        if (response.length === 1) {
          setSelectedFreightId((prev) => (prev ? prev : response[0].freightId));
        }
      }
    }
    loadFreights();
  }, [api]);

  return (
    <Dialog open={true} className="smallCourseDialog">
      <DialogTitle>
        {isFirstTime
          ? Translate.get("AddFreight")
          : Translate.get("UpdateFreight")}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={1}>
          <FormControl fullWidth>
            <InputLabel id="freight-select-label">
              {Translate.get("SelectFreight")}
            </InputLabel>
            <Select
              disabled={!freights || freights.length === 0}
              labelId="freight-select-label"
              id="freight-select"
              value={selectedFreightId ?? ""}
              label={Translate.get("SelectFreight")}
              onChange={(event) => setSelectedFreightId(event.target.value)}
            >
              {freights &&
                freights.map((freight) => (
                  <MenuItem key={freight.freightId} value={freight.freightId}>
                    {freight.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            disabled={!selectedFreightId}
            label={Translate.get("Weight")}
            value={weight ?? ""}
            onChange={(event) => setWeight(event.target.value)}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              type: "number",
              min: 0,
            }}
          />
          <TextField
            fullWidth
            disabled={!selectedFreightId}
            label={Translate.get("FixedPrice")}
            value={fixedPrice ?? ""}
            onChange={(event) => setFixedPrice(event.target.value)}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              type: "number",
              min: 0,
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {Translate.get("Cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          disabled={!selectedFreightId || (!weight && !fixedPrice)}
          onClick={async () =>
            await onSave(selectedFreightId, weight, fixedPrice)
          }
        >
          {Translate.get("Save")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
}
