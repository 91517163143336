import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import HtmlEditor from "../HtmlEditor";
import Translate from "../../utils/Translate";
import { useState } from "react";

export default function EditEmailPreviewDialog({
  onClose,
  onOk,
  subject: subjectProp,
  htmlBody: htmlBodyProp,
  attachmentImages,
}) {
  const [subject, setSubject] = useState(subjectProp);
  const [htmlBody, setHtmlBody] = useState(htmlBodyProp);

  return (
    <Dialog open={true} onClose={onClose} className="mediumCourseDialog">
      <DialogTitle>{Translate.get("PreeView")}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2} marginTop={1}>
          <TextField
            fullWidth
            label={Translate.get("Subject")}
            value={subject}
            onChange={(evt) => setSubject(evt.target.value)}
          />
          <HtmlEditor
            label={Translate.get("Message")}
            htmlContent={htmlBody}
            images={attachmentImages}
            onBlur={setHtmlBody}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
        <Button onClick={() => onOk({ subject, htmlBody, attachmentImages })}>
          {Translate.get("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
