import Translate from "../../../utils/Translate";
import Personnummer from "personnummer";
import { validatePhoneNumber } from "../../../utils/phoneNumber";

const emailPattern = `([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+`; // https://stackoverflow.com/a/14075810
const emailRegex = new RegExp(emailPattern);

const tryParsePersonalNumber = (pnr) => {
  const result = {
    isValid: true,
    isCoordinationNumber: false,
    formattedPersonalNumber: pnr,
  };
  if (!Personnummer.valid(pnr)) {
    result.isValid = false;
    return result;
  }

  result.isValid = true;
  const parsed = Personnummer.parse(pnr);
  if (parsed.isCoordinationNumber()) {
    result.isCoordinationNumber = true;
    return result;
  }
  const longYear = pnr.length > 10;
  let formatted;
  if (longYear) {
    formatted = parsed.format(true);
    // hack to get hyphen
    formatted = formatted.substring(0, 8) + "-" + formatted.substring(8);
  } else {
    formatted = parsed.format(false);
  }
  result.formattedPersonalNumber = formatted;

  return result;
};

const existInvalidOrgNo = (actors) =>
  actors.some((x) => {
    const result = tryParsePersonalNumber(x.orgNo ?? "");
    return !result.isValid;
  });

const existCoordinationOrgNo = (actors) =>
  actors.some((x) => {
    const result = tryParsePersonalNumber(x.orgNo ?? "");
    return result.isCoordinationNumber;
  });

// there is at least one actor with neither valid mail or phone number
const existInvalidEmailAndPhone = (actors) =>
  actors.some(
    (x) =>
      !(
        (x.email && emailRegex.test(x.email)) ||
        (x.phoneNumber && validatePhoneNumber(x.phoneNumber))
      )
  );

const existInvalidEmail = (actors) =>
  actors.some((x) => !(x.email && emailRegex.test(x.email)));

export const validateDriverActors = (actors) => {
  if (!actors || actors.length < 1) {
    return Translate.get("PermitLeastOneholderProceed");
  }
  if (existInvalidOrgNo(actors)) {
    return Translate.get("AllHoldersOrgNoMustBeValid");
  }
  if (existInvalidEmailAndPhone(actors)) {
    return Translate.get("AllHoldersEmailOrMobilePhoneMustBeValid");
  }
  if (existCoordinationOrgNo(actors)) {
    return Translate.get("CoordinationNumberNotSupported");
  }
};

export const validateDriverActor = (actor) => {
  const actors = [actor];
  if (existInvalidOrgNo(actors)) {
    return Translate.get("InvalidOrgNo");
  }
  if (existInvalidEmailAndPhone(actors)) {
    return Translate.get("ValidEmailOrPhoneRequired");
  }
  if (existCoordinationOrgNo(actors)) {
    return Translate.get("CoordinationNumberNotSupported");
  }
};

// TODO kan jag göra dessa meddelandena mer generiska? Kommer nog visas på instrukrören
export const validateResponsibleActor = (actor) => {
  if (!actor) throw new Error();
  const actors = [actor];
  if (existInvalidOrgNo(actors)) {
    return Translate.get("ResponsibleOrgNoMustBeValid");
  }
  if (existInvalidEmailAndPhone(actors)) {
    return Translate.get("ResponsibleEmailOrMobilePhoneMustBeValid");
  }
  if (existCoordinationOrgNo(actors)) {
    return Translate.get("CoordinationNumberNotSupported");
  }
};

export const validateEmailRecipient = (actor) => {
  if (!actor) throw new Error();
  if (!actor.email) {
    return Translate.get("EmailRequired");
  }
  if (existInvalidEmail([actor])) {
    return Translate.get("InvalidEmail");
  }
};
