export const orderStatus = {
    canceled: 0,
    missingInfo: 9,
    registered: 10,
    registeredAutoOrder: 11,
    ordered: 20,
    underPicking: 30,
    waitingForCourseInvoice: 35,
    picked: 40,
    pickedAutoOrder: 41,
    waitingForInvoice: 50,
    sentToERP: 60,
    invoicedInERP: 65,
    partlyPaid: 70,
    paid: 80,
    finished: 90,
  };