import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Typography, DialogActions } from '@mui/material';
import Translate from "../../../utils/Translate";
import { Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import { AddFilter, DeleteFilter, GetFilters } from './MyFilterApi';
import { useApi } from "../../../utils/Api";
import Preloader from '../../Preloader';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { getUniqueValues, getUniqueValuesByCondition } from './MyFilterCommon';

const MyActorLicenseFilters = ({ open, onClose, data, setFilter }) => {

  const api = useApi();

  const [isLoading, setIsLoading] = useState(true);

  const [courseParticipantName, setCourseParticipantName] = useState("");
  const [courseParticipantEmail, setCourseParticipantEmail] = useState("");

  const [licenseGroup, setLicenseGroup] = useState("")
  const [licenseGroupOptions, setLicenseGroupOptions] = useState([]);

  const [competences, setCompetences] = useState([]);
  const [competenceOptions, setCompetenceOptions] = useState([])

  const [instructor, setInstructor] = useState("");
  const [instructorOptions, setInstructorOptions] = useState([]);

  const [validFromOperator, setValidFromOperator] = useState("");
  const [validFrom, setValidFrom] = useState(dayjs().format('YYYY-MM-DD'));

  const [validToOperator, setValidToOperator] = useState("");
  const [validTo, setValidTo] = useState(dayjs().format('YYYY-MM-DD'));

  const [filters, setFilters] = useState(new Map([]));

  const [selectedFilter, setSelectedFilter] = useState("new");

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [newFilterName, setNewFilterName] = useState("");

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);


  useEffect(async () => {
    let licenseGroupOptions = getUniqueValues(data, "licenseGroupDesc");
    setLicenseGroupOptions(licenseGroupOptions.sort());

    let instructorOptions = getUniqueValues(data, "teacher");
    setInstructorOptions(instructorOptions.sort());

    var filters = await GetFilters(1, api, setIsLoading);
    setFilters(filters);

  }, []);


  function handleCourseParticipantNameChange(event) {
    setCourseParticipantName(event.target.value);
  }

  function handleCourseParticipantEmailChange(event) {
    setCourseParticipantEmail(event.target.value);
  }

  function handleLicenseGroupChange(event) {
    let licenseGroupDesc = event.target.value
    setLicenseGroup(licenseGroupDesc);

    setCompetences([]);
    let competenceOptions = getUniqueValuesByCondition(data, "licenseGroupDesc", licenseGroupDesc, "licenseGroupPractice")
    setCompetenceOptions(competenceOptions.sort());
  }

  function handleCompetenceChange(event) {
    setCompetences(event.target.value);
  }

  function handleInstructorChange(event) {
    setInstructor(event.target.value);
  };

  function handleValidFromOperatorChange(event) {
    setValidFromOperator(event.target.value)
  }

  function handleValidFromDateChange(newDate) {
    setValidFrom(newDate);
  }

  function handleValidToOperatorChange(event) {
    setValidToOperator(event.target.value)
  }

  function handleValidToDateChange(newDate) {
    setValidTo(newDate);
  }

  function handleCloseSaveDialog() {
    setNewFilterName("");
    setOpenSaveDialog(false);
  }

  function handleCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  async function handleSaveFilter() {
    let filter = buildExpression();
    let jsFilter = JSON.stringify(filter);

    let reqObj = {
      name: newFilterName,
      filter: jsFilter,
      filterType: 1
    }

    let res = await AddFilter(reqObj, api, setIsLoading);
    if (res === true) {
      handleCloseSaveDialog();
      let filters = await GetFilters(1, api, setIsLoading);
      setFilters(filters);
      setSelectedFilter(reqObj.name);
    }
  }

  async function handleDeleteFilter() {
    let req = {
      name: selectedFilter
    }

    let res = await DeleteFilter(req, api, setIsLoading);
    if (res === true) {
      let filters = await GetFilters(1, api, setIsLoading);
      setFilters(filters);
      setSelectedFilter("new");
      setOpenDeleteDialog(false);
      resetStates();
    }
  }

  function handleFilterClick() {
    let expr = buildExpression();

    buildMuiFilterFromMyFilter(expr);
    onClose();
  }

  function handleFilterChange(event) {
    setSelectedFilter(event.target.value);
    if (event.target.value === "new") {
      resetStates();
      return;
    }

    setFormValuesFromExpr(event.target.value);
  }

  function buildExpression() {
    let arr = [];

    if (courseParticipantName !== "") {
      let expr = {
        field: "courseParticipantName",
        operator: "contains",
        value: courseParticipantName,
      }
      arr.push(expr);
    }

    if (courseParticipantEmail !== "") {
      let expr = {
        field: "courseParticipantEmail",
        operator: "contains",
        value: courseParticipantEmail,
      }
      arr.push(expr);
    }

    if (licenseGroup !== "") {
      let expr = {
        field: "licenseGroup",
        operator: "equals",
        value: licenseGroup,
      }
      arr.push(expr)
    }

    if (competences.length > 0) {
      let sortedStr = competences.sort().join(", ");

      let expr = {
        field: "competences",
        operator: "contains",
        value: sortedStr,
      }
      arr.push(expr);
    }

    if (instructor !== "") {
      let expr = {
        field: "instructor",
        operator: "equals",
        value: instructor,
      }
      arr.push(expr);
    }

    if (validFromOperator !== "" && validFrom !== "") {
      let expr = {
        field: "validFrom",
        operator: validFromOperator,
        value: dayjs(validFrom).format('YYYY-MM-DD'),
      }
      arr.push(expr);
    }

    if (validToOperator !== "" && validTo !== "") {
      let expr = {
        field: "validTo",
        operator: validToOperator,
        value: dayjs(validTo).format('YYYY-MM-DD'),
      }
      arr.push(expr);
    }

    return arr;
  }

  function buildMuiFilterFromMyFilter(filters) {
    let exprs = new Map(filters.map(obj => [obj.field, obj]));

    let arr = [];
    if (exprs.has("courseParticipantName")) {
      let expr = {
        id: 5,
        field: "studentName",
        operator: exprs.get("courseParticipantName").operator,
        value: exprs.get("courseParticipantName").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("courseParticipantEmail")) {
      let expr = {
        id: 6,
        field: "studentEmail",
        operator: exprs.get("courseParticipantEmail").operator,
        value: exprs.get("courseParticipantEmail").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("licenseGroup")) {
      let expr = {
        id: 7,
        field: "licenseGroupDesc",
        operator: exprs.get("licenseGroup").operator,
        value: exprs.get("licenseGroup").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("competences")) {
      let expr = {
        id: 8,
        field: "licenseGroupPractice",
        operator: exprs.get("competences").operator,
        value: exprs.get("competences").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("instructor")) {
      let expr = {
        id: 9,
        field: "teacher",
        operator: exprs.get("instructor").operator,
        value: exprs.get("instructor").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("validFrom")) {
      let expr = {
        id: 10,
        field: "validFrom",
        operator: exprs.get("validFrom").operator,
        value: exprs.get("validFrom").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("validTo")) {
      let expr = {
        id: 11,
        field: "validTo",
        operator: exprs.get("validTo").operator,
        value: exprs.get("validTo").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    setFilter({ items: arr });
  }

  function setFormValuesFromExpr(filterName) {
    resetStates();

    var filter = filters.get(filterName);
    let expr = new Map(filter.map(obj => [obj.field, obj]));

    if (expr.has("courseParticipantName")) {
      setCourseParticipantName(expr.get("courseParticipantName").value);
    }

    if (expr.has("courseParticipantEmail")) {
      setCourseParticipantEmail(expr.get("courseParticipantEmail").value);
    }

    if (expr.has("licenseGroup")) {
      setLicenseGroup(expr.get("licenseGroup").value);

    }

    if (expr.has("licenseGroup") && expr.has("competences")) {
      let compArray = expr.get("competences").value.split(',').map(item => item.trim());
      setCompetences(compArray);

      let competenceOptions = getUniqueValuesByCondition(data, "licenseGroupDesc", expr.get("licenseGroup").value, "licenseGroupPractice")
      setCompetenceOptions(competenceOptions);
    }

    if (expr.has("instructor")) {
      setInstructor(expr.get("instructor").value)
    }

    if (expr.has("validFrom")) {
      setValidFromOperator(expr.get("validFrom").operator);
      setValidFrom(expr.get("validFrom").value)
    }

    if (expr.has("validTo")) {
      setValidToOperator(expr.get("validTo").operator)
      setValidTo(expr.get("validTo").value);
    }
  }

  function resetStates() {
    setCourseParticipantName("");
    setCourseParticipantEmail("");
    setLicenseGroup("");
    setInstructor("");
    setCompetences([]);
    setValidFromOperator("");
    setValidFrom(dayjs().format('YYYY-MM-DD'));
    setValidToOperator("");
    setValidTo(dayjs().format('YYYY-MM-DD'));
  }

  if (isLoading) {
    return <Preloader />
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {Translate.get("Filter2")}
      </DialogTitle>
      <DialogContent>
        <div style={{ width: "22em" }}>
          <b><p>{Translate.get("Filter2")}</p></b>

          <FormControl fullWidth margin="normal">
            <InputLabel>{Translate.get("ChooseQuickFilter")}</InputLabel>
            <Select
              value={selectedFilter}
              onChange={handleFilterChange}
              label={Translate.get("ChooseQuickFilter")}
            >
              <MenuItem key={1} value="new"><em>{Translate.get("New2")}</em></MenuItem>
              {Array.from(filters.keys()).map((filter) => (
                <MenuItem key={filter} value={filter}>
                  {filter.charAt(0).toUpperCase() + filter.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ marginTop: "0.1em", marginBottom: "1em", display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              onClick={() => setOpenDeleteDialog(true)}
              style={{ marginRight: "0.2em" }}
              disabled={selectedFilter === "new"}
            >
              <DeleteIcon />
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenSaveDialog(true)}
            >
              <SaveIcon />
            </Button>
          </div>

          <b><p style={{ marginBottom: "1em" }}>{Translate.get("Details")}</p></b>

          <TextField
            value={courseParticipantName}
            onChange={handleCourseParticipantNameChange}
            label={Translate.get("StudentName")}
            fullWidth
          >
          </TextField>

          <TextField
            value={courseParticipantEmail}
            onChange={handleCourseParticipantEmailChange}
            label={Translate.get("Student") + " " + Translate.get("Email")}
            fullWidth
            style={{ marginTop: "1em" }}
          >
          </TextField>

          <FormControl fullWidth variant="outlined"
            style={{ marginTop: "1em" }}
          >
            <InputLabel id="course-group-label">{Translate.get("LicenseGroup")}</InputLabel>
            <Select
              labelId="course-group-label"
              value={licenseGroup}
              onChange={handleLicenseGroupChange}
              label={Translate.get("LicenseGroup")}
            >
              <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
              {licenseGroupOptions.map((group, index) => (
                <MenuItem key={index} value={group}>
                  {group}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: "1em" }}>
            <InputLabel id="competence-label">{Translate.get("Competences")}</InputLabel>
            <Select
              labelId="competence-label"
              multiple
              value={competences}
              onChange={handleCompetenceChange}
              renderValue={(selected) => selected.join(', ')}
              label={Translate.get("Competences")}
            >
              {competenceOptions.map((competence) => (
                <MenuItem key={competence} value={competence}>
                  <Checkbox checked={competences.indexOf(competence) > -1} />
                  <ListItemText primary={competence} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth variant="outlined" style={{ marginTop: "1em" }}>
            <InputLabel id="instructor-select-label">{Translate.get("Instructor")}</InputLabel>
            <Select
              labelId="instructor-select-label"
              value={instructor}
              onChange={handleInstructorChange}
              label={Translate.get("Instructor")}
            >
              <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
              {instructorOptions.map((instructor, index) => (
                <MenuItem key={index} value={instructor}>
                  {instructor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid container style={{ marginTop: "1em" }}>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ paddingRight: "0.1em" }}>
                <InputLabel id="valid-select-label">{Translate.get("ValidFrom")}</InputLabel>
                <Select
                  value={validFromOperator}
                  onChange={handleValidFromOperatorChange}
                  labelId="instructor-select-label"
                  label={Translate.get("ValidFrom")}
                >
                  <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
                  <MenuItem value="onOrBefore">{Translate.get("Before")}</MenuItem>
                  <MenuItem value="onOrAfter">{Translate.get("After")}</MenuItem>
                </Select>
              </FormControl>


            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label={Translate.get("Date")}
                value={validFrom}
                onChange={handleValidFromDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: "1em" }}>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ paddingRight: "0.1em" }}>
                <InputLabel id="valid-select-label">{Translate.get("ValidTo")}</InputLabel>
                <Select
                  value={validToOperator}
                  onChange={handleValidToOperatorChange}
                  labelId="instructor-select-label"
                  label={Translate.get("ValidTo")}
                >
                  <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
                  <MenuItem value="onOrbefore">{Translate.get("Before")}</MenuItem>
                  <MenuItem value="onOrAfter">{Translate.get("After")}</MenuItem>
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label={Translate.get("Date")}
                value={validTo}
                onChange={handleValidToDateChange}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
          </Grid>
        </div>

        <div style={{ marginTop: "2em", display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
          <Button variant="contained" onClick={handleFilterClick}>
            {Translate.get("Filter")}
          </Button>
        </div>

        <Dialog open={openSaveDialog} onClose={handleCloseSaveDialog}>
          <DialogTitle>{Translate.get("SaveQuickFilter")}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {Translate.get("ChooseNameForQuickFilter")}
            </Typography>
            <TextField
              fullWidth
              label={Translate.get("Name")}
              variant="outlined"
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
              inputProps={{ maxLength: 50 }}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSaveDialog} variant="outlined">
              Avbryt
            </Button>
            <Button onClick={handleSaveFilter} variant="contained">
              {Translate.get("Save")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>{Translate.get("RemoveQuickFilter")}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {Translate.get("SureDeleteQuickFilter")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} variant="outlined">
              Avbryt
            </Button>
            <Button onClick={() => handleDeleteFilter()} variant="contained">
              {Translate.get("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default MyActorLicenseFilters;