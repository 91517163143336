import { CustomNoRowsOverlay } from "../../../utils/StyledGridOverlay";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useApi } from "../../../utils/Api";
import Preloader from "../../Preloader";
import Translate from "../../../utils/Translate";
import ButtonWithSpinner from "../../ButtonWithSpinner";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getCurrentDateMask,
  getCurrentInputFormat,
} from "../../../utils/dateTimeFormat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  AccordionActions,
} from "@mui/material";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import EditIcon from "@mui/icons-material/Edit";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SchoolIcon from "@mui/icons-material/School";
import DomainIcon from "@mui/icons-material/Domain";
import MoneyIcon from "@mui/icons-material/Money";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InfoIcon from "@mui/icons-material/Info";
import DotMenu from "../../DotMenu";
import ChangeEPortLicenseTypeDialog from "./ChangeEPortLicenseTypeDialog";
import EditEPortLicenseLine from "./EditEPortLicenseLine";
import {
  DataGridPremium as DataGrid,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { IconAndLabelColumnHeader } from "../../../utils/dataGrid";
import { dataGridLang } from "../../MemToolBar";
import {
  AdminPanelSettings,
  GroupWork,
  ScatterPlot,
  Delete,
} from "@mui/icons-material";
import EPortProfileDialog from "./EPortProfileDialog";
import YesOrNoDialog from "../../YesOrNoDialog";
import { AlertDialog } from "../../AlertDialog";

const lineTypes = {
  undefined: 0,
  license: 1,
  user: 2,
  SMS: 3,
  ePortReportClient: 4,
  iframe: 5,
  courseAdminHTML: 7,
  actorPermit: 8,
  actorPermitUser: 9,
  courseAdminReadOnly: 10,
  userReadOnly: 11,
};

export default function EPortLicenseDialog({
  companyActorId,
  externalDataId,
  defaults,
}) {
  const [ePortLicense, setEPortLicense] = useState({
    actorId: companyActorId,
    startDate: null,
    endDate: null,
    orderCanUpdate: true,
    ...(!externalDataId ? defaults : {}),
  });
  const [originalTerminateDate, setOriginalTerminateDate] = useState(null);
  const [originalEndDate, setOriginalEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState();
  const [licenseParts, setLicenseParts] = useState([]);
  const [resellableELearningPartsByType, setResellableELearningPartsByType] =
    useState([]);
  const [selectedELearningParts, setSelectedELearningParts] = useState({});
  const [licenseLineParts, setLicenseLineParts] = useState({});
  const [prices, setPrices] = useState({});
  const [contacts, setContacts] = useState([]);
  const [validation, setValidation] = useState({});
  const [orderNoRequired, setOrderNoRequired] = useState(false);
  const [companyDefaultCustomerOrderNo, setCompanyDefaultCustomerOrderNo] =
    useState(null);
  const [currency, setCurrency] = useState(null);

  const [showEndDateRenewInfo, setShowEndDateRenewInfo] = useState(false);
  const [showLicenseTypeDialog, setShowLicenseTypeDialog] = useState(false);
  const [canChangeLicenseType, setCanChangeLicenseType] = useState(true);
  const [lineIdToEdit, setLineIdToEdit] = useState(null);
  const [showAutoCancelOrderQuestion, setShowAutoCancelOrderQuestion] =
    useState(false);
  const [
    showAutoOrderCancelNotPossibleQuestion,
    setShowAutoOrderCancelNotPossibleQuestion,
  ] = useState(false);
  const [showAutoCreateOrderQuestion, setShowAutoCreateQuestion] =
    useState(false);

  const localizedTextsMap = dataGridLang();

  const api = useApi();
  const location = useLocation();
  const navigate = useNavigate();
  const closeAndReload = () => {
    //Soft reload with redirect
    const hash = window.location.hash.endsWith("#dialog")
      ? window.location.hash.substring(0, window.location.hash.length - 7)
      : window.location.hash;
    const loc = encodeURIComponent(
      `${location.pathname}${location.search}${hash}`
    );
    navigate(`/redirect/${loc}`, { replace: true });
  };

  const loadEPortLicense = useCallback(
    async (actorId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}licenses/eport/${actorId}`,
        false,
        "GET"
      );

      if (response && response.isSuccessful) {
        setEPortLicense(response.license);
        setOriginalTerminateDate(response.license.terminateDate);
        setOriginalEndDate(response.license.endDate);
        return response.license;
      }
    },
    [api]
  );

  const loadResellableELearningParts = useCallback(async () => {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}warehouse/parts/resellable-elearning`,
      false,
      "GET"
    );
    if (response && response.isSuccessful) {
      // Only include eTruck and eLift
      const eTruckParts = response.parts.filter((p) => p.eLearningType === 1);
      const eLiftParts = response.parts.filter((p) => p.eLearningType === 2);
      const eFallParts = response.parts.filter((p) => p.eLearningType === 4);
      setResellableELearningPartsByType({ 1: eTruckParts, 2: eLiftParts, 4: eFallParts });
    }
  }, [api]);

  const loadCompanyOrderInfo = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }

      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/orderinfo`,
        false,
        "GET"
      );
      if (response.isSuccessful) {
        setCurrency((prevCurr) =>
          prevCurr ? prevCurr : response.defaultCurrency
        );
        setOrderNoRequired(response.defaultCustomerOrderNoRequired);
        setCompanyDefaultCustomerOrderNo(response.defaultCustomerOrderNo);
        if (response.defaultCustomerOrderNo && !externalDataId) {
          setEPortLicense((prev) => ({
            ...prev,
            customerOrderNo:
              prev.customerOrderNo ?? response.defaultCustomerOrderNo,
          }));
        }
      }
    },
    [api, externalDataId]
  );

  const loadLicenseParts = useCallback(async () => {
    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}warehouse/parts/for-eport-license`,
      false,
      "GET"
    );
    if (response && response.isSuccessful) {
      setLicenseParts(response.parts);
      return response.parts;
    }
  }, [api]);

  const loadLicenseLineParts = useCallback(
    async (licensePartId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts/for-eport-license-line/${licensePartId}`,
        false,
        "GET"
      );
      if (response && response.isSuccessful) {
        const partsById = Object.fromEntries(
          response.parts.map((p) => [p.partId, p])
        );
        setLicenseLineParts((prev) => ({
          ...prev,
          [licensePartId]: partsById,
        }));
        return response.parts;
      }
    },
    [api]
  );

  const loadPartPrices = useCallback(
    async (partIds, actorId) => {
      if (!partIds || partIds.length === 0) {
        return;
      }

      const request = partIds.map((partId) => ({
        partId: partId,
        actorId: actorId,
        qty: 1,
      }));

      const isOkOverrideFn = (response) => response && Array.isArray(response);

      const response = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}warehouse/parts/get-prices`,
        request,
        "POST",
        isOkOverrideFn
      );
      if (isOkOverrideFn(response)) {
        const pricesByPartId = Object.fromEntries(
          response.map((item) => [item.partId, item])
        );
        setPrices((prevPrices) => ({ ...prevPrices, ...pricesByPartId }));
        return pricesByPartId;
      }
    },
    [api]
  );

  const loadContacts = useCallback(
    async (actorId) => {
      if (!actorId) {
        return;
      }
      const isOkOverrideFn = (response) => response && Array.isArray(response);
      const loadedContacts = await api.fetchWithOverride(
        `${process.env.REACT_APP_MAIN_URL}actors/clients/${actorId}/contacts`,
        false,
        "GET",
        isOkOverrideFn
      );
      if (isOkOverrideFn(loadedContacts)) {
        setContacts(loadedContacts);
      } else {
        setContacts([]);
      }
    },
    [api]
  );

  const getPartEndDate = useCallback(
    async (partId, startDate) => {
      if (!partId || !startDate) {
        return;
      }

      const response = await api.fetchWithOverride(
        `${
          process.env.REACT_APP_MAIN_URL
        }warehouse/parts/${partId}/end-date?startDate=${startDate.toISOString()}`,
        false,
        "GET",
        (response) => !!response
      );
      if (!!response) {
        return new Date(response);
      }
    },
    [api]
  );

  useEffect(() => {
    async function loadAll() {
      let ePortLicense;
      if (externalDataId) {
        ePortLicense = await loadEPortLicense(companyActorId);
      }

      const [, , parts, lineParts] = await Promise.all([
        loadCompanyOrderInfo(companyActorId),
        loadContacts(companyActorId),
        loadLicenseParts(),
        ePortLicense
          ? loadLicenseLineParts(ePortLicense.partId)
          : Promise.resolve([]),
        !externalDataId ? loadResellableELearningParts() : Promise.resolve(),
      ]);
      const partIds = parts.map((p) => p.partId);
      const linePartIds = lineParts.map((p) => p.partId);
      await loadPartPrices([...partIds, ...linePartIds], companyActorId);
    }

    setIsLoading(true);
    loadAll().then(() => {
      setIsLoading(false);
    });
  }, [
    companyActorId,
    externalDataId,
    api,
    loadLicenseParts,
    loadPartPrices,
    loadContacts,
    loadCompanyOrderInfo,
    loadEPortLicense,
    loadLicenseLineParts,
    loadResellableELearningParts,
  ]);

  // Validation
  useEffect(() => {
    const contact =
      ePortLicense.actorContactId &&
      contacts.find((x) => x.actorId == ePortLicense.actorContactId);
    // console.log("###VALIDATION CONTACT", contact)
    if (contact && !contact.email) {
      setValidation((prev) => ({
        ...prev,
        actorContact: Translate.get("EmailRequired"),
      }));
    } else {
      setValidation((prev) => ({ ...prev, actorContact: null }));
    }
  }, [ePortLicense]);

  async function postAddOrUpdateEPortLicense(autoCancelOrder) {
    const request = {
      ...ePortLicense,
      eLearningTypeToELearningCode: !externalDataId
        ? selectedELearningParts
        : null,
      autoCancelOrder,
    };

    const response = await api.fetch(
      `${process.env.REACT_APP_MAIN_URL}licenses/eport`,
      request,
      "POST"
    );

    if (response && response.isSuccessful) {
      closeAndReload();
    }
  }

  function handleCancel() {
    closeAndReload();
  }

  async function handleSave() {
    const isTerminating =
      !originalTerminateDate && !!ePortLicense.terminateDate;
    const isUnterminating =
      !!originalTerminateDate && !ePortLicense.terminateDate;
    if (isTerminating && ePortLicense.orderId) {
      const orderCanUpdate =
        ePortLicense.orderCanUpdate ||
        ePortLicense.orderCanUpdate === null ||
        ePortLicense.orderCanUpdate === undefined;

      orderCanUpdate
        ? setShowAutoCancelOrderQuestion(true)
        : setShowAutoOrderCancelNotPossibleQuestion(true);
    } else if (isUnterminating) {
      setShowAutoCreateQuestion(true);
    } else {
      await postAddOrUpdateEPortLicense();
    }
  }

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function updateTerminateDate(newValue) {
    setEPortLicense((prev) => ({
      ...prev,
      terminateDate: newValue,
    }));
    setCanChangeLicenseType(false);
  }

  async function handlePartSelected(partId) {
    if (partId === ePortLicense.partId) {
      return;
    }

    // Here we will set defaults if not yet set, but avoid changing if already set
    const startDate = ePortLicense.startDate ?? new Date();
    const endDate = await getPartEndDate(partId, startDate);
    setEPortLicense((prevLic) => ({
      ...prevLic,
      // price: prices[partId].price, <- No, because this is only used for fixed prices
      partId,
      startDate,
      endDate,
    }));

    if (!licenseLineParts[partId]) {
      const lineParts = await loadLicenseLineParts(partId);
      const linePartIds = lineParts.map((p) => p.partId);
      await loadPartPrices(linePartIds, companyActorId);
    }
  }

  function handleContactSelected(event) {
    const actorId = event.target.value;

    const orderCanUpdate =
      ePortLicense.orderCanUpdate ||
      ePortLicense.orderCanUpdate === null ||
      ePortLicense.orderCanUpdate === undefined;

    //Only update customerOrderNo automatically if it's a new order, or if it's empty
    if (
      !ePortLicense.ePortCloudLicenseId ||
      (orderCanUpdate && !ePortLicense.customerOrderNo)
    ) {
      if (ePortLicense.actorContactId > 0) {
        const previousContact = contacts.find(
          (c) => c.actorId === ePortLicense.actorContactId
        );
        // Reset to company customerOrderNo if deselecting a contact
        if (
          previousContact &&
          previousContact.defaultCustomerOrderNo ===
            ePortLicense.customerOrderNo
        ) {
          setEPortLicense((prevLic) => ({
            ...prevLic,
            customerOrderNo: companyDefaultCustomerOrderNo,
          }));
        }
      }
      const contact = contacts.find((c) => c.actorId === actorId);
      if (contact.defaultCustomerOrderNo) {
        setEPortLicense((prevLic) => ({
          ...prevLic,
          customerOrderNo: contact.defaultCustomerOrderNo,
        }));
      }
    }
    setEPortLicense((prevLic) => ({ ...prevLic, actorContactId: actorId }));
    setCanChangeLicenseType(false);
  }

  async function updateEndDate(startDate) {
    const endDate = await getPartEndDate(ePortLicense.partId, startDate);
    setEPortLicense((prev) => ({ ...prev, endDate }));

    const now = new Date();
    const renewMaxDate = addDays(now, 30);
    if (originalEndDate != endDate && endDate < renewMaxDate) {
      setShowEndDateRenewInfo(true);
    }
  }

  async function handleLicenseTypeChanged() {
    setIsLoading(true);
    setLicenseLineParts({});
    const ePortLicense = await loadEPortLicense(companyActorId);
    await loadLicenseLineParts(ePortLicense.partId);
    setIsLoading(false);
  }

  const getLicenseLineDotMenuActions = useCallback(
    (licenseLine) => {
      return [
        {
          icon: <EditIcon />,
          text: Translate.get("EditLicenseLine"),
          disabled: !licenseLine.canEdit || !!ePortLicense?.price,
          onClick: () => setLineIdToEdit(licenseLine.ePortCloudLicenseLineId),
        },
      ];
    },
    [ePortLicense]
  );

  function handleSaveChangedLicenseLine(licenseLine) {
    const index = ePortLicense.ePortCloudLicenseLine.findIndex(
      (ll) => ll.ePortCloudLicenseLineId == licenseLine.ePortCloudLicenseLineId
    );
    if (index !== -1) {
      const lines = [...ePortLicense.ePortCloudLicenseLine];
      lines.splice(index, 1, licenseLine);
      setEPortLicense((prev) => ({ ...prev, ePortCloudLicenseLine: lines }));
    }
    setLineIdToEdit(null);
  }

  const licenseLineColumns = useMemo(
    () => [
      {
        field: "customDescription",
        flex: 2,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Name"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ListAltIcon}
            label={Translate.get("Name")}
          />
        ),
        valueGetter: (params) =>
          licenseLineParts[ePortLicense.partId]
            ? licenseLineParts[ePortLicense.partId][params.row.partId]
                .description
            : "",
      },
      {
        field: "price",
        flex: 0.6,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Price"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={MoneyIcon}
            label={Translate.get("Price")}
          />
        ),
        valueGetter: (params) => (!!ePortLicense?.price ? "-" : params.value),
      },
      {
        field: "qty",
        flex: 0.6,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Quantity"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ScatterPlot}
            label={Translate.get("Quantity")}
          />
        ),
      },
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        hideable: false,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu
            content={getLicenseLineDotMenuActions(params.row)}
            horizontal
          />
        ),
      },
    ],
    [getLicenseLineDotMenuActions, ePortLicense, licenseLineParts]
  );

  // console.log(
  //   "####licenseLineParts",
  //   licenseLineParts,
  //   ePortLicense.ePortCloudLicenseLine
  // );

  return (
    <>
      {isLoading && (
        <>
          <Preloader />
          <DialogContent className="stdDialogContent"></DialogContent>
        </>
      )}
      {!isLoading && (
        <>
          <DialogContent>
            <Stack
              sx={{
                paddingTop: "16px",
              }}
              spacing={2.5}
            >
              {!ePortLicense.orderCanUpdate &&
                ePortLicense.orderCanUpdate !== null &&
                ePortLicense.orderCanUpdate !== undefined && (
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                      gap: 2.3,
                      paddingLeft: "9px",
                      // paddingRight: "50px",
                      paddingBottom: "16px",
                      color: "text.secondary",
                    }}
                  >
                    <InfoIcon />
                    {Translate.get("EPortLicenseOrderAlreadyInvoiceInfo")}
                  </Typography>
                )}

              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <VerifiedUserIcon sx={{ marginLeft: "9px" }} />
                <FormControl fullWidth>
                  <InputLabel id="part-select-label" required>
                    {Translate.get("EPortLicense")}
                  </InputLabel>
                  <Select
                    required
                    disabled={ePortLicense.ePortCloudLicenseId > 0}
                    labelId="part-select-label"
                    id="part-select"
                    label={Translate.get("EPortLicense")}
                    value={ePortLicense.partId ?? ""}
                    onChange={(event) => handlePartSelected(event.target.value)}
                  >
                    {licenseParts.map((x) => (
                      <MenuItem key={x.partId} value={x.partId}>
                        {x.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {ePortLicense.ePortCloudLicenseId > 0 && (
                  <Button
                    disabled={!canChangeLicenseType}
                    onClick={() => setShowLicenseTypeDialog(true)}
                  >
                    {Translate.get("Alter")}
                  </Button>
                )}
              </Stack>

              {!externalDataId &&
                resellableELearningPartsByType &&
                Object.entries(resellableELearningPartsByType).map(
                  ([eLearningType, parts]) => (
                    <Stack
                      key={eLearningType}
                      direction="row"
                      spacing={1}
                      className="stdFlexLeft"
                    >
                      <SchoolIcon sx={{ marginLeft: "9px" }} />
                      <FormControl fullWidth>
                        <InputLabel
                          id={"part-select-label-" + eLearningType}
                          required
                        >
                          {Translate.getELearningType(Number(eLearningType))}
                        </InputLabel>
                        <Select
                          required
                          labelId={"part-select-label-" + eLearningType}
                          id={"part-select-" + eLearningType}
                          label={Translate.getELearningType(
                            Number(eLearningType)
                          )}
                          value={selectedELearningParts[eLearningType] ?? ""}
                          onChange={(event) =>
                            setSelectedELearningParts((prev) => ({
                              ...prev,
                              [eLearningType]: event.target.value,
                            }))
                          }
                        >
                          {parts.map((x) => (
                            <MenuItem
                              key={x.eLearningCode}
                              value={x.eLearningCode}
                            >
                              {x.description}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                  )
                )}

              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <DomainIcon sx={{ marginLeft: "9px" }} />
                <TextField
                  required
                  fullWidth
                  value={ePortLicense?.companyName ?? ""}
                  InputProps={{ spellCheck: false }}
                  onChange={(event) => {
                    setEPortLicense((prev) => ({
                      ...prev,
                      companyName: event.target.value,
                    }));
                    setCanChangeLicenseType(false);
                  }}
                  label={Translate.get("Name")}
                  inputProps={{ maxLength: 30 }}
                />
              </Stack>

              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <HeadsetMicIcon sx={{ marginLeft: "9px" }} />
                <FormControl
                  fullWidth
                  sx={{ marginTop: "10px" }}
                  error={!!validation.actorContact}
                >
                  <InputLabel id="contact-select-label" required>
                    {Translate.get("Contact")}
                  </InputLabel>
                  <Select
                    required
                    disabled={!contacts || contacts.length === 0}
                    labelId="contact-select-label"
                    id="contact-select"
                    value={ePortLicense?.actorContactId ?? ""}
                    label={Translate.get("Contact")}
                    onChange={handleContactSelected}
                  >
                    {contacts &&
                      contacts.map((contact) => (
                        <MenuItem key={contact.actorId} value={contact.actorId}>
                          {contact.actorName}
                        </MenuItem>
                      ))}
                  </Select>
                  {validation.actorContact && (
                    <FormHelperText>{validation.actorContact}</FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <ListAltIcon sx={{ marginLeft: "9px" }} />
                <TextField
                  // Can't require, because sometimes we get the CustomerOrderNo after actually sending the order confirmation
                  // required={orderNoRequired}
                  fullWidth
                  value={ePortLicense?.customerOrderNo ?? ""}
                  InputProps={{ spellCheck: false }}
                  onChange={(event) => {
                    setEPortLicense((prev) => ({
                      ...prev,
                      customerOrderNo: event.target.value,
                    }));
                    setCanChangeLicenseType(false);
                  }}
                  label={Translate.get("YourOrderNo")}
                  inputProps={{ maxLength: 30 }}
                />
              </Stack>
              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <MoneyIcon sx={{ marginLeft: "9px" }} />
                <TextField
                  fullWidth
                  value={ePortLicense?.price ?? ""}
                  InputProps={{ spellCheck: false }}
                  onChange={(event) => {
                    setEPortLicense((prev) => ({
                      ...prev,
                      price: event.target.value,
                    }));
                    setCanChangeLicenseType(false);
                  }}
                  label={Translate.get("FixedPrice")}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    type: "number",
                  }}
                />
              </Stack>

              <Stack direction="row" spacing={1} className="stdFlexLeft">
                <CalendarTodayIcon sx={{ marginLeft: "9px" }} />
                <DatePicker
                  disabled={!ePortLicense.partId}
                  inputFormat={getCurrentInputFormat()}
                  mask={getCurrentDateMask()}
                  label={Translate.get("StartDate")}
                  value={ePortLicense.startDate}
                  onChange={(newValue) => {
                    updateEndDate(newValue);
                    setEPortLicense((prev) => ({
                      ...prev,
                      startDate: newValue,
                    }));
                    setCanChangeLicenseType(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: Translate.get("DatePlaceholder"),
                      }}
                    />
                  )}
                />
                {/* Always disabled because EndDate is based on StartDate */}
                <DatePicker
                  disabled
                  inputFormat={getCurrentInputFormat()}
                  mask={getCurrentDateMask()}
                  label={Translate.get("EndDate")}
                  value={ePortLicense.endDate}
                  onChange={(newValue) => {
                    setEPortLicense((prev) => ({
                      ...prev,
                      endDate: newValue,
                    }));
                    setCanChangeLicenseType(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: Translate.get("DatePlaceholder"),
                      }}
                    />
                  )}
                />
              </Stack>

              {ePortLicense.ePortCloudLicenseId && (
                <Stack direction="row" spacing={1} className="stdFlexLeft">
                  <DoNotDisturbIcon sx={{ marginLeft: "9px" }} />
                  <DatePicker
                    inputFormat={getCurrentInputFormat()}
                    mask={getCurrentDateMask()}
                    label={Translate.get("TerminateDateLabel")}
                    value={
                      ePortLicense.terminateDate
                        ? ePortLicense.terminateDate
                        : null
                    }
                    onChange={updateTerminateDate}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: Translate.get("DatePlaceholder"),
                        }}
                      />
                    )}
                  />
                </Stack>
              )}

              {ePortLicense.ePortCloudLicenseId > 0 && (
                <Box marginTop="16px">
                  <Accordion
                    className="advancedSettingsAccordion"
                    disableGutters
                  >
                    <AccordionSummary
                      sx={{ paddingLeft: 0, paddingRight: "6px" }}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <div className="smallHeader">{`${Translate.get(
                        "EPortLicenseLines"
                      )} (${ePortLicense.ePortCloudLicenseLine.length})`}</div>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0 }}>
                      <DataGrid
                        className="dgSmall textbox"
                        rows={ePortLicense.ePortCloudLicenseLine}
                        columns={licenseLineColumns}
                        disableSelectionOnClick
                        getRowId={(row) => row.ePortCloudLicenseLineId}
                        autoHeight={true}
                        components={{
                          Toolbar: GridToolbar,
                          NoRowsOverlay: CustomNoRowsOverlay(
                            Translate.get("EmptyHere")
                          ),
                        }}
                        componentsProps={{
                          columnsPanel: { className: "customColumnPanel" },
                          filterPanel: { className: "customfilterPanel" },
                          panel: { className: "customPanel" },
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: "lineType", sort: "desc" }],
                          },
                        }}
                        localeText={localizedTextsMap}
                        disableDensitySelector={true}
                        disableColumnMenu
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>{Translate.get("Cancel")}</Button>
            <ButtonWithSpinner variant="contained" onClick={handleSave}>
              {Translate.get("Save")}
            </ButtonWithSpinner>
          </DialogActions>
        </>
      )}
      {showLicenseTypeDialog && (
        <ChangeEPortLicenseTypeDialog
          companyActorId={companyActorId}
          onChanged={handleLicenseTypeChanged}
          onClose={() => setShowLicenseTypeDialog(false)}
        />
      )}
      {!!lineIdToEdit && (
        <EditEPortLicenseLine
          description={
            licenseLineParts[ePortLicense.partId]
              ? licenseLineParts[ePortLicense.partId][
                  ePortLicense.ePortCloudLicenseLine.find(
                    (ll) => ll.ePortCloudLicenseLineId == lineIdToEdit
                  )?.partId
                ].description
              : ""
          }
          licenseLine={ePortLicense.ePortCloudLicenseLine.find(
            (ll) => ll.ePortCloudLicenseLineId == lineIdToEdit
          )}
          onSave={(licenseLine) => handleSaveChangedLicenseLine(licenseLine)}
          onClose={() => setLineIdToEdit(null)}
        />
      )}
      <YesOrNoDialog
        open={showAutoCancelOrderQuestion}
        title={Translate.get("CancelOrder")}
        text={Translate.get("CancelEPortLicenseOrderQuestion")}
        onNo={async () => await postAddOrUpdateEPortLicense(false)}
        onYes={async () => await postAddOrUpdateEPortLicense(true)}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
        onCancel={() => setShowAutoCancelOrderQuestion(false)}
        showCancelButton
      />
      <YesOrNoDialog
        open={showAutoOrderCancelNotPossibleQuestion}
        title={Translate.get("CancelOrderNotPossible")}
        text={Translate.get("CancelEPortLicenseOrderNotPossibleInfo")}
        onNo={() => setShowAutoOrderCancelNotPossibleQuestion(false)}
        onYes={async () => await postAddOrUpdateEPortLicense(false)}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
      />
      <YesOrNoDialog
        open={showAutoCreateOrderQuestion}
        title={Translate.get("AutoCreateOrder")}
        text={Translate.get("UnterminateEPortLicenseAutoCreateOrderQuestion")}
        onNo={() => setShowAutoCreateQuestion(false)}
        onYes={postAddOrUpdateEPortLicense}
        noText={Translate.get("No")}
        yesText={Translate.get("Yes")}
      />
      <AlertDialog
        titleText={Translate.get("AutoRenew")}
        bodyText={Translate.get("AutoRenweLicenseEndDateInfo")}
        buttonText={Translate.get("Ok")}
        open={!!showEndDateRenewInfo}
        onClose={() => setShowEndDateRenewInfo(false)}
      />
    </>
  );
}
