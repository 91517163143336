import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";

export default function StUnitField({
  qty,
  isUnlimitedQty,
  locationArea,
  blocked,
  revision,
}) {
  //   function TooltipField({ label, value }) {
  //     return <Box whiteSpace="pre">{`${label}:\t\t${value}`}</Box>;
  //   }

  function TooltipField({ label, value }) {
    return (
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={6} className="stUnitTooltipField">{`${label}:`}</Grid>
        <Grid item xs={6} className="stUnitTooltipField" textAlign="right">
          {value}
        </Grid>
      </Grid>
    );
  }

  return (
    <Tooltip
      title={
        <Grid container width="150px">
          <TooltipField
            label={Translate.get("Quantity")}
            value={isUnlimitedQty ? "∞" : qty}
          />
          <TooltipField
            label={Translate.get("StUnitAreaLabel")}
            value={locationArea ?? "-"}
          />
          <TooltipField
            label={Translate.get("StUnitRevisionLabel")}
            value={revision ?? "-"}
          />
          <TooltipField
            label={Translate.get("Status")}
            value={
              blocked
                ? Translate.get("StUnitStatusBlocked")
                : Translate.get("Ok")
            }
          />
        </Grid>
      }
      arrow
    >
      <Stack
        className="stUnitField"
        direction="row"
        spacing={1}
        alignItems="center"
      >
        <Box className={"stUnitStatus " + (blocked ? "blocked" : "ok")}></Box>
        <Box>{isUnlimitedQty ? "∞" : qty}</Box>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          <Box>{locationArea ?? "??"}</Box>
        </Typography>
      </Stack>
    </Tooltip>
  );
}
