import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Typography, DialogActions } from '@mui/material';
import Translate from "../../../utils/Translate";
import { Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import { AddFilter, DeleteFilter, GetFilters } from './MyFilterApi';
import { useApi } from "../../../utils/Api";
import Preloader from '../../Preloader';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { getUniqueValues } from './MyFilterCommon';

const MyActorSkillFilters = ({ open, onClose, data, setFilter }) => {
    const api = useApi();

    const [isLoading, setIsLoading] = useState(false);

    const [skillDateOperator, setSkillDateOperator] = useState("");
    const [skillDate, setSkillDate] = useState(dayjs().format('YYYY-MM-DD'));

    const [skillDate2Operator, setSkillDate2Operator] = useState("");
    const [skillDate2, setSkillDate2] = useState(dayjs().format('YYYY-MM-DD'));

    const [competenceCodes, setCompetenceCodes] = useState([]);
    const [competenceCodeOptions, setCompetenceCodeOptions] = useState([]);

    const [competenceTexts, setCompetenceTexts] = useState([]);
    const [competenceTextOptions, setCompetenceTextOptions] = useState([]);

    const [hasPassed, setHasPassed] = useState("");

    const [filters, setFilters] = useState(new Map([]));

    const [selectedFilter, setSelectedFilter] = useState("new");

    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [newFilterName, setNewFilterName] = useState("");

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(async () => {
        let competenceCodeOptions = getUniqueValues(data, "skillCode");
        setCompetenceCodeOptions(competenceCodeOptions.sort());

        let competenceTextOptions = getUniqueValues(data, "skillDesc");
        setCompetenceTextOptions(competenceTextOptions.sort());

        var filters = await GetFilters(4, api, setIsLoading);
        setFilters(filters);
    }, []);


    function handleSkillDateOperatorChange(event) {
        setSkillDateOperator(event.target.value);
    }

    function handleSkillDateChange(newDate) {
        setSkillDate(newDate);
    }

    function handleSkillDate2OperatorChange(event) {
        setSkillDate2Operator(event.target.value);
    }

    function handleSkillDate2Change(newDate) {
        setSkillDate2(newDate);
    }

    function handleCompetenceCodeChange(event) {
        setCompetenceCodes(event.target.value);
    }

    function handleCompetenceTextChange(event) {
        setCompetenceTexts(event.target.value);
    }

    function handleHasPassedChange(event) {
        setHasPassed(event.target.value);
    }

    function handleCloseSaveDialog() {
        setNewFilterName("");
        setOpenSaveDialog(false);
    }

    function handleCloseDeleteDialog() {
        setOpenDeleteDialog(false);
    }

    async function handleSaveFilter() {
        let filter = buildExpression();
        let jsFilter = JSON.stringify(filter);

        let reqObj = {
            name: newFilterName,
            filter: jsFilter,
            filterType: 4
        }

        let res = await AddFilter(reqObj, api, setIsLoading);
        if (res === true) {
            handleCloseSaveDialog();
            let filters = await GetFilters(4, api, setIsLoading);
            setFilters(filters);
            setSelectedFilter(reqObj.name);
        }
    }

    async function handleDeleteFilter() {
        let req = {
            name: selectedFilter
        }

        let res = await DeleteFilter(req, api, setIsLoading);
        if (res === true) {
            let filters = await GetFilters(4, api, setIsLoading);
            setFilters(filters);
            setSelectedFilter("new");
            setOpenDeleteDialog(false);
            resetStates();
        }
    }

    function handleFilterClick() {
        let expr = buildExpression();

        buildMuiFilterFromMyFilter(expr);
        onClose();
    }

    function handleFilterChange(event) {
        setSelectedFilter(event.target.value);
        if (event.target.value === "new") {
            resetStates();
            return;
        }

        setFormValuesFromExpr(event.target.value);
    }

    function buildExpression() {
        let arr = [];

        if (skillDateOperator !== "" && skillDate !== "") {
            let expr = {
                field: "skillDate",
                operator: skillDateOperator,
                value: dayjs(skillDate).format('YYYY-MM-DD'),
            }
            arr.push(expr);
        }

        if (skillDate2Operator !== "" && skillDate2 !== "") {
            let expr = {
                field: "skillDate2",
                operator: skillDate2Operator,
                value: dayjs(skillDate2).format('YYYY-MM-DD'),
            }
            arr.push(expr);
        }

        if (competenceCodes.length > 0) {
            let sortedStr = competenceCodes.sort().join(", ");

            let expr = {
                field: "competenceCodes",
                operator: "contains",
                value: sortedStr,
            }
            arr.push(expr);
        }

        if (competenceTexts.length > 0) {
            let sortedStr = competenceTexts.sort().join(", ");

            let expr = {
                field: "competenceTexts",
                operator: "contains",
                value: sortedStr,
            }
            arr.push(expr);
        }

        if (hasPassed !== "") {
            let expr = {
                field: "hasPassed",
                operator: "is",
                value: hasPassed.toString(),
            }
            arr.push(expr);
        }

        return arr;
    }

    function buildMuiFilterFromMyFilter(filters) {
        let exprs = new Map(filters.map(obj => [obj.field, obj]));

        let arr = [];

        if (exprs.has("skillDate")) {
            let expr = {
                id: 7,
                field: "skillDate",
                operator: exprs.get("skillDate").operator,
                value: exprs.get("skillDate").value,
                filterName: "myFilter",
            }
            arr.push(expr);
        }

        if (exprs.has("skillDate2")) {
            let expr = {
                id: 8,
                field: "skillDate",
                operator: exprs.get("skillDate2").operator,
                value: exprs.get("skillDate2").value,
                filterName: "myFilter",
            }
            arr.push(expr);
        }

        if (exprs.has("competenceCodes")) {
            let expr = {
                id: 9,
                field: "skillCode",
                operator: exprs.get("competenceCodes").operator,
                value: exprs.get("competenceCodes").value,
                filterName: "myfilter",
            }
            arr.push(expr)
        }

        if (exprs.has("competenceTexts")) {
            let expr = {
                id: 10,
                field: "skillDesc",
                operator: exprs.get("competenceTexts").operator,
                value: exprs.get("competenceTexts").value,
                filterName: "myfilter",
            }
            arr.push(expr)
        }

        if (exprs.has("hasPassed")) {
            let expr = {
                id: 11,
                field: "hasPassed",
                operator: exprs.get("hasPassed").operator,
                value: exprs.get("hasPassed").value,
                filterName: "myfilter",
            }
            arr.push(expr)
        }

        setFilter({ items: arr });
    }

    function setFormValuesFromExpr(filterName) {
        resetStates();

        var filter = filters.get(filterName);
        let expr = new Map(filter.map(obj => [obj.field, obj]));

        if (expr.has("skillDate")) {
            setSkillDateOperator(expr.get("skillDate").operator);
            setSkillDate(expr.get("skillDate").value)
        }

        if (expr.has("skillDate2")) {
            setSkillDate2Operator(expr.get("skillDate2").operator);
            setSkillDate2(expr.get("skillDate2").value)
        }

        if (expr.has("competenceCodes")) {
            let compArray = expr.get("competenceCodes").value.split(',').map(item => item.trim());
            setCompetenceCodes(compArray);
        }

        if (expr.has("competenceTexts")) {
            let compArray = expr.get("competenceTexts").value.split(',').map(item => item.trim());
            setCompetenceTexts(compArray);
        }

        if (expr.has("hasPassed")) {
            setHasPassed(expr.get("hasPassed").value);
        }
    }

    function resetStates() {
        setSkillDate(dayjs().format('YYYY-MM-DD'));
        setSkillDateOperator("");
        setSkillDate2(dayjs().format('YYYY-MM-DD'));
        setSkillDate2Operator("");
        setCompetenceCodes([]);
        setCompetenceTexts([]);
        setHasPassed("");
    }

    if (isLoading) {
        return <Preloader />
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {Translate.get("Filter2")}
            </DialogTitle>
            <DialogContent>
                <div style={{ width: "22em" }}>
                    <b><p>{Translate.get("Filter2")}</p></b>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>{Translate.get("ChooseQuickFilter")}</InputLabel>
                        <Select
                            value={selectedFilter}
                            onChange={handleFilterChange}
                            label={Translate.get("ChooseQuickFilter")}
                        >
                            <MenuItem key={1} value="new"><em>{Translate.get("New2")}</em></MenuItem>
                            {Array.from(filters.keys()).map((filter) => (
                                <MenuItem key={filter} value={filter}>
                                    {filter.charAt(0).toUpperCase() + filter.slice(1)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div style={{ marginTop: "0.1em", marginBottom: "1em", display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            style={{ marginRight: "0.2em" }}
                            disabled={selectedFilter === "new"}
                        >
                            <DeleteIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenSaveDialog(true)}
                        >
                            <SaveIcon />
                        </Button>
                    </div>

                    <b><p style={{ marginBottom: "1em" }}>{Translate.get("Details")}</p></b>

                    <Grid container style={{ marginTop: "1em" }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth style={{ paddingRight: "0.1em" }}>
                                <InputLabel id="skillDate-label">{Translate.get("Approved")}</InputLabel>
                                <Select
                                    value={skillDateOperator}
                                    onChange={handleSkillDateOperatorChange}
                                    labelId="skillDate-label"
                                    label={Translate.get("Approved")}
                                >
                                    <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
                                    <MenuItem value="onOrBefore">{Translate.get("Before")}</MenuItem>
                                    <MenuItem value="onOrAfter">{Translate.get("After")}</MenuItem>
                                </Select>
                            </FormControl>


                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label={Translate.get("Date")}
                                value={skillDate}
                                onChange={handleSkillDateChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: "1em" }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth style={{ paddingRight: "0.1em" }}>
                                <InputLabel id="skillDate-label">{Translate.get("Approved")}</InputLabel>
                                <Select
                                    value={skillDate2Operator}
                                    onChange={handleSkillDate2OperatorChange}
                                    labelId="skillDate-label"
                                    label={Translate.get("Approved")}
                                >
                                    <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
                                    <MenuItem value="onOrBefore">{Translate.get("Before")}</MenuItem>
                                    <MenuItem value="onOrAfter">{Translate.get("After")}</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label={Translate.get("Date")}
                                value={skillDate2}
                                onChange={handleSkillDate2Change}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Grid>
                    </Grid>

                    <FormControl fullWidth variant="outlined" style={{ marginTop: "1em" }}>
                        <InputLabel id="competence-label">{Translate.get("ShortCode")}</InputLabel>
                        <Select
                            labelId="competence-label"
                            multiple
                            value={competenceCodes}
                            onChange={handleCompetenceCodeChange}
                            renderValue={(selected) => selected.join(', ')}
                            label={Translate.get("ShortCode")}
                        >
                            {competenceCodeOptions.map((competence) => (
                                <MenuItem key={competence} value={competence}>
                                    <Checkbox checked={competenceCodes.indexOf(competence) > -1} />
                                    <ListItemText primary={competence} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{ marginTop: "1em" }}>
                        <InputLabel id="competence-label">{Translate.get("Competence")}</InputLabel>
                        <Select
                            labelId="competence-label"
                            multiple
                            value={competenceTexts}
                            onChange={handleCompetenceTextChange}
                            renderValue={(selected) => selected.join(', ')}
                            label={Translate.get("Competence")}
                        >
                            {competenceTextOptions.map((competence) => (
                                <MenuItem key={competence} value={competence}>
                                    <Checkbox checked={competenceTexts.indexOf(competence) > -1} />
                                    <ListItemText primary={competence} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth style={{ marginTop: "1em" }}>
                        <InputLabel id="boolean-select-label">{Translate.get("HasPassed")}</InputLabel>
                        <Select
                            labelId="boolean-select-label"
                            id="boolean-select"
                            value={hasPassed}
                            label="Select Option"
                            onChange={handleHasPassedChange}
                        >
                            <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
                            <MenuItem value={true}>{Translate.get("True")}</MenuItem>
                            <MenuItem value={false}>{Translate.get("False")}</MenuItem>
                        </Select>
                    </FormControl>

                </div>


                <div style={{ marginTop: "2em", display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
                    <Button variant="contained" onClick={handleFilterClick}>
                        {Translate.get("Filter")}
                    </Button>

                </div>

                <Dialog open={openSaveDialog} onClose={handleCloseSaveDialog}>
                    <DialogTitle>{Translate.get("SaveQuickFilter")}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            {Translate.get("ChooseNameForQuickFilter")}
                        </Typography>
                        <TextField
                            fullWidth
                            label={Translate.get("Name")}
                            variant="outlined"
                            value={newFilterName}
                            onChange={(e) => setNewFilterName(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSaveDialog} variant="outlined">
                            Avbryt
                        </Button>
                        <Button onClick={handleSaveFilter} variant="contained">
                            {Translate.get("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                    <DialogTitle>{Translate.get("RemoveQuickFilter")}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            {Translate.get("SureDeleteQuickFilter")}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} variant="outlined">
                            Avbryt
                        </Button>
                        <Button onClick={() => handleDeleteFilter()} variant="contained">
                            {Translate.get("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContent>
        </Dialog>
    );
};

export default MyActorSkillFilters;