// this object should match the eLearningType enum in ePortWebLibrary
export const eLearningTypes = Object.freeze({
  1: "ETruck",
  2: "ELift",
  3: "ETruckRepetition",
  4: "EFall"
});

export const multiELearningTypes = Object.freeze({
  24: "MultiELiftAndEFall",
});

// These are used like eLearningLicenses in some cases,
// but don't really have eLearningTypes in DB
export const pseudoELearningTypes = Object.freeze({
  1000: "ExtraFinalExam",
});

export const eLearningTypeEnum = Object.freeze({
  ETruck: 1,
  ELift: 2,
  ETruckRepetition: 3,
  EFall: 4
});

export const pseudoELearningTypeEnum = Object.freeze({
  ExtraFinalExam: 1000,
});
