import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import Translate from '../../../utils/Translate';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useState } from 'react';
import { useApi } from '../../../utils/Api';
import TextField from '@mui/material/TextField';
import HtmlEditor from '../../HtmlEditor';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import WarningIcon from '@mui/icons-material/Warning';
import { stringToColor } from '../../../utils/formatString';
import { useMediaQuery } from "@mui/material";
import Preloader from '../../Preloader';

export default function SendCourseBookingOrderConfirmation({ showSendOrderConfirmatinEmail: showSendOrderConfirmationEmail, receiverActorId, receiverActorName, receiverActorEmail, orderId, open, setIsOpen }) {
    const api = useApi();
    const [emailSubject, setEmailSubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showEditEmail, setShowEditEmail] = useState(false);
    const [attachmentImages, setAttachmentImages] = useState([]);

    const smallScreen = useMediaQuery('(max-width:700px)');

    useEffect(async () => {
        await getEmailPreview(orderId, receiverActorId);
    }, []);

    async function getEmailPreview(orderId, receiverActorId) {
        setIsLoading(true);
        let reqObj = {
            "orderId": orderId,
            "receiverActorId": receiverActorId
        }
        const data = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}orders/confirmation/email/preview`,
            reqObj,
            "POST"
        );
        setIsLoading(false);

        if (data.isSuccessful) {
            setEmailSubject(data.emailSubject);
            setEmailBody(data.htmlBody.html);
            setAttachmentImages(data.htmlBody.attachments.map((a) => ({name: "logotype.png", url: a.dataBase64})))
        }
    }

    async function sendEmail() {
        setIsLoading(true);
        let reqObj = {
            "orderId": orderId,
            "receiverActorId": receiverActorId,
            "subject": emailSubject,
            "htmlBody": emailBody
        }

        let res = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}orders/confirmation/email`,
            reqObj,
            "POST"
        );
        setIsLoading(false);

        if (res.isSuccessful) {
            setIsOpen(false);
            close();
        }
    }

    function handleClose() {
        showSendOrderConfirmationEmail(false);
    }

    function close() {
        window.history.back();
    };

    return (
        <>
            <Dialog open={open}>
                {isLoading && <Preloader />}
                {showEditEmail &&
                    <div style={{ margin: "1em" }}>
                        <TextField
                            sx={{ width: 1, mb: 2, mt: 2 }}
                            label={Translate.get('Subject')}
                            value={emailSubject}
                            onChange={(evt) => setEmailSubject(evt.target.value)}
                        />
                        <HtmlEditor
                            label={Translate.get('Message')}
                            htmlContent={emailBody}
                            images={attachmentImages}
                            onBlur={setEmailBody}
                        />

                        <DialogActions>
                            <Button onClick={() => setShowEditEmail(false)}>
                                {Translate.get("Close")}
                            </Button>
                        </DialogActions>
                    </div>}

                {!showEditEmail &&
                    (<div style={{width: smallScreen ? "95%" : "30em"}}>
                    
                    <DialogTitle>{Translate.get("SendOrderConfirmation")}</DialogTitle>

                        <DialogContent dividers>
                        <div className="stdList">
                            <Grid container spacing={2}>
                                <Grid item xs={10}>                                  
                                        <div className="tableAvatar">
                                            <Avatar
                                                alt={receiverActorName}
                                                //src={cp.profilePictureUrl}
                                                sx={{ bgcolor: stringToColor(receiverActorName) }}
                                            ></Avatar>
                                            <div>
                                                <div className="tableAvatarTitle bold">{receiverActorName}</div>
                                                <div>
                                                    {receiverActorEmail ? (
                                                        receiverActorEmail
                                                    ) : (
                                                        <div className="smallRedWarning">
                                                            <WarningIcon fontSize="8" />
                                                            <div> {Translate.get('InvalidEmail')}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                </Grid>
                                <Grid item xs={2}>                      
                                    <Tooltip title={Translate.get("EditMessage")}>
                                        <IconButton onClick={() => setShowEditEmail(true)}><EditIcon /></IconButton>
                                    </Tooltip>                              
                                </Grid>
                            </Grid>
                            </div>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                {Translate.get("Close")}
                            </Button>
                            <Button 
                                onClick={sendEmail}
                                disabled={!receiverActorEmail}
                            >
                                {Translate.get("Send")}
                            </Button>
                        </DialogActions>
                    </div>)}
            </Dialog>
        </>
    );
};
