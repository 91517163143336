const langCodeLocales = {
  "9": "en-GB",
  "12": "fr-FR",
  "29": "sv-SE",
};

const langCodeCurrencies = {
  "9": "GBP",
  "12": "EUR",
  "29": "SEK",
};

export function formatMoney(amount, localeParam, currencyParam) {
  const locale = localeParam ?? langCodeLocales[localStorage.lang] ?? "sv-SE";
  const currency = currencyParam ?? langCodeCurrencies[localStorage.lang];

  const formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
}
