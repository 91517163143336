import { Box, Stack } from "@mui/material";

export default function AddressPrintView({ street1, street2, zipCode, city }) {
  return (
    <Stack direction="column" color="black">
      <Box>{street1}</Box>
      <Box>{street2}</Box>
      <Box>{`${zipCode} ${city}`}</Box>
    </Stack>
  );
}
