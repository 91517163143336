import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import Preloader from "../Preloader";
import { dataGridLang } from '../MemToolBar';
import Translate from "../../utils/Translate";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../utils/Api";
import { avatarColumn, textColumn, dateColumn, StatisticsGridToolBar } from "./StatisticsCommon";
import { CustomNoRowsOverlay } from '../../utils/StyledGridOverlay';
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BuildIcon from "@mui/icons-material/Build";
import PersonIcon from "@mui/icons-material/Person";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import PlaceIcon from "@mui/icons-material/Place";
import SchoolIcon from "@mui/icons-material/School";
import BusinessIcon from "@mui/icons-material/Business";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDateWithMonthOffSet } from '../../utils/dateTimeFormat';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import TagIcon from '@mui/icons-material/Tag';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import FlagIcon from "@mui/icons-material/Flag";
import StarIcon from '@mui/icons-material/Star';
import MyCourseParticipantFilters from "./MyFilters/MyCourseParticipantFilters";


const CourseParticipantStatistics = () => {
    const colNames = {
        dataId: 'dataId',
        courseId: 'courseId',
        partDesc: 'partDesc',
        licenseDesc: 'licenseDesc',
        shortComment: 'shortComment',
        fromDate: 'fromDate',
        toDate: 'toDate',
        courseCity: 'courseCity',
        teacher: 'teacher',
        teacherActorId: 'teacherActorId',
        responible: 'responible',
        studentName: 'studentName',
        studentOrgNo: 'studentOrgNo',
        studentExternalActorNo: 'studentExternalActorNo',
        studentNationalityLabel: 'studentNationalityLabel',
        studentActorId: 'studentActorId',
        studentActorPictureUrl: 'studentActorPictureUrl',
        licenseGroupPractice: 'licenseGroupPractice',
        licenseGroupTheory: 'licenseGroupTheory',
        companyName: 'companyName',
        companyCity: 'companyCity',
    };

    const columns = [
        // textColumn(colNames.dataId, null, 'DataId'),
        // textColumn(colNames.courseId, null, 'CourseId'),
        textColumn(colNames.licenseDesc, <SchoolIcon />, 'CourseType'),
        textColumn(colNames.partDesc, null, 'CourseNameLong'),
        textColumn(colNames.shortComment, null, 'ShortComment'),
        dateColumn(colNames.fromDate, <CalendarTodayIcon />, 'FromDate'),
        dateColumn(colNames.toDate, <EventAvailableIcon />, 'ToDate'),
        textColumn(colNames.courseCity, <PlaceIcon />, 'CourseCity'),
        textColumn(colNames.teacher, <CoPresentIcon />, 'Instructor'),
        textColumn(colNames.teacherActorId, <CoPresentIcon />, 'TeacherActorId'),
        //textColumn(colNames.responible, null, 'Responible'), // saknar data
        avatarColumn(colNames.studentName, null, colNames.studentActorPictureUrl, <PersonIcon />, 'Name'),
        textColumn(colNames.studentOrgNo, <FingerprintIcon />, 'PersonalNo'),
        textColumn(colNames.studentExternalActorNo, <TagIcon />, 'EmployeeNo'),
        textColumn(colNames.studentNationalityLabel, <FlagIcon />, 'Nationality'),
        // textColumn(colNames.studentActorId, null, 'StudentActorId'),
        textColumn(colNames.licenseGroupTheory, <AutoStoriesIcon />, 'GradeTheoretical'),
        textColumn(colNames.licenseGroupPractice, <BuildIcon />, 'GradePractical'),
        textColumn(colNames.companyName, <BusinessIcon />, 'Company'),
        textColumn(colNames.companyCity, null, 'City'),
    ];

    const initialHiddenColumns = [
        //colNames.partDesc,
        //colNames.licenseDesc,
        colNames.shortComment,
        colNames.toDate,
        //colNames.courseCity,
        colNames.teacher,
        colNames.teacherActorId,
        //colNames.studentName,
        //colNames.studentOrgNo,
        colNames.licenseGroupPractice,
        colNames.licenseGroupTheory,
        //colNames.companyName,
        colNames.companyCity,
        colNames.studentExternalActorNo,
        colNames.studentNationalityLabel,
    ];

    const localizedTextsMap = dataGridLang();
    const initialGridState = {
        sorting: { sortModel: [{ field: colNames.studentName, sort: 'asc' }] },
        columns: {
            columnVisibilityModel: initialHiddenColumns.reduce((ac, a) => ({ ...ac, [a]: false }), {})
        }
    };


    let i = 0;
    const navigate = useNavigate();
    const [licenses, setLicenses] = useState();
    const smallScreen = useMediaQuery('(max-width:700px)');
    const [dataFetched, setDataFetched] = useState(false);
    const api = useApi();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [stdFilters, setStdFilters] = useState(["SelectFilter", "MyCourseParticipants", "MyFilteredInstructorsCps", "CourseParticipantLastMonth", "CoursePartipantUpcoming"]);
    const [selectedStdFilter, setSelectedStdFilter] = useState("SelectFilter");
    const [filt, setFilt] = useState({
        items: []
    });

    const [teacherActorIds, setTeacherActorIds] = useState([]);
    const [actorId, setActorId] = useState("");

    const [openMyFilters, setOpenMyFilters] = useState(false);

    useEffect(async () => {
        await loadData();
    }, [api]);

    async function loadData() {
        const [data, filterData] =
            await Promise.all([
                api.fetch(
                    `${process.env.REACT_APP_MAIN_URL}courses/participants/report`,
                    false,
                    "GET"
                ),
                api.fetch(
                    `${process.env.REACT_APP_MAIN_URL}actors/teachers/filter/actorids`,
                    false,
                    "GET"
                ),
            ]);

        if (data && filterData) {
            let d = data.isSuccessful === false ? [] : data;
            d.forEach((element, index) => {
                element.id = index;
                element.studentNationalityLabel = element.studentNationality ?
                    Translate.getCountryName(element.studentNationality) :
                    null;
            });
            setLicenses(d);

            if (filterData.isSuccessful !== false) {
                setTeacherActorIds(filterData.teacherActorIds);
                if (filterData.teacherActorIds.length < 1) {
                    setStdFilters(stdFilters.filter((i) => (i !== "MyFilteredInstructorsCps")))
                }
                setActorId(filterData.actorId);
                if (!filterData.actorId) {
                    setStdFilters(stdFilters.filter((i) => (i !== "MyCourseParticipants")))
                }
            }
        }
        setDataFetched(true);
    }

    const navigateToActor = (actorId) => {
        navigate(`/dashboard/person/${actorId}`, { replace: false });
    };

    function handleStdFilterChange(event) {
        setSelectedStdFilter(event.target.value);
        setStdFilter(event.target.value);
    }

    function setStdFilter(filterName) {
        switch (filterName) {

            case "SelectFilter":
                setFilt({ items: [] });
                break;

            case "MyCourseParticipants":
                setFilt({
                    ...filt,
                    items:
                        [{
                            id: 1,
                            field: "teacherActorId",
                            operator: "equals",
                            value: actorId.toString(),
                            filterName: "MyCourseParticipants"
                        }]
                });
                break;

            case "MyFilteredInstructorsCps":
                if (teacherActorIds.length < 1) {
                    enqueueSnackbar(Translate.get('FiltersMissingOrEmpty'), {
                        variant: 'warning',
                        autoHideDuration: 3000,
                    });

                    setFilt({ items: [] });
                    break;
                }

                setFilt({
                    ...filt,
                    items:
                        [{
                            id: 2,
                            field: "teacherActorId",
                            operator: "isAnyOf",
                            value: teacherActorIds.map(String),
                            filterName: "MyFilteredInstructorsCps"
                        }]
                });
                break;

            case "CourseParticipantLastMonth":
                setFilt({
                    ...filt,
                    items:
                        [
                            {
                                id: 3,
                                field: "toDate",
                                operator: "onOrAfter",
                                value: formatDateWithMonthOffSet(new Date(), -1),
                                filterName: "CourseParticipantLastMonth"
                            },
                        ]
                });
                break;

            case "CoursePartipantUpcoming":
                setFilt({
                    ...filt,
                    items:
                        [
                            {
                                id: 4,
                                field: "toDate",
                                operator: "onOrAfter",
                                value: formatDateWithMonthOffSet(new Date(), 0),
                                filterName: "CoursePartipantUpcoming"
                            },
                        ]
                });
                break;

            default:
                return;
        }
    }

    function clearFilters() {
        setFilt({ items: [] });
        setSelectedStdFilter("SelectFilter");
    }

    function handleFilterModelChange(newFilterModel, x) {
        if (x.reason === "restoreState") {
            return;
        }

        if (x.reason === "changeLogicOperator") {
            setFilt({
                ...filt,
                logicOperator: newFilterModel.logicOperator
            });
            return;
        }

        const newFilt = { items: newFilterModel.items, logicOperator: newFilterModel.logicOperator };
        setFilt(newFilt);

        var stdFilterExistsInFilters = newFilt.items.findIndex(e => e.filterName === selectedStdFilter)
        if (stdFilterExistsInFilters === -1) {
            setSelectedStdFilter("SelectFilter");
        }
    }

    return <div style={{ height: "70vh", /* border:"1px solid blue" */ }}> {/* TODO: är 70vh bra? */}
        {!dataFetched && <Preloader invisible={true} />}
        {dataFetched &&

            <>
                <Grid container justifyContent="flex-start" sx={{ ml: 1 }}>

                    <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{Translate.get('CpStatisticsInfo')}</div>}>
                        <IconButton>
                            <InfoIcon color="primary" />
                        </IconButton>
                    </Tooltip>

                    <FormControl sx={{ minWidth: "5em" }}>
                        <InputLabel id="std-filter">{Translate.get("StdFilter") ?? " "}</InputLabel>
                        <Select
                            sx={{ maxWidth: smallScreen ? "10em" : "100%" }}
                            labelId="std-filter"
                            id="std-filter"
                            name="MyCourseParticipantsFilter"
                            value={selectedStdFilter}
                            label={Translate.get("StdFilter") ?? " "}
                            onChange={handleStdFilterChange}
                        >
                            {stdFilters.map((element) =>
                                <MenuItem key={element} value={element} name={element}>{Translate.get(element)}</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    <Button
                        onClick={() => clearFilters()}
                        variant="outlined"
                        disabled={filt.items?.length === 0}
                        startIcon={<DeleteIcon />}
                        sx={{ ml: "1em" }}
                        size="xlarge"

                    >
                        {Translate.get("Purge")}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => setOpenMyFilters(true)}
                        startIcon={<StarIcon />}
                        style={{ marginLeft: "1em" }}
                    >
                        {Translate.get("MyFilters")}
                    </Button>
                    <MyCourseParticipantFilters
                        open={openMyFilters}
                        onClose={() => setOpenMyFilters(false)}
                        data={licenses}
                        setFilter={setFilt}
                    />
                </Grid>

                <DataGridPremium
                    getRowId={row => row.id}
                    rows={licenses}
                    filterModel={filt}
                    onFilterModelChange={(newFilterModel, x) => handleFilterModelChange(newFilterModel, x)}
                    columns={columns}
                    disableColumnMenu={true}
                    onRowClick={(params) => navigateToActor(params.row.studentActorId)}
                    pagination={true}
                    pageSizeOptions={[10, 50, 100]}
                    localeText={localizedTextsMap}
                    components={{
                        Toolbar: () => StatisticsGridToolBar("courseParticipantStatistics", "Students"),
                        NoRowsOverlay: CustomNoRowsOverlay(Translate.get('EmptyHere'))
                    }}
                    density='comfortable'
                    disableDensitySelector={true}
                    initialState={initialGridState}
                    disableRowSelectionOnClick
                // onExcelExportStateChange={(newState) =>{ 
                //     setDataFetched(newState !== "pending");
                // }}
                />
            </>
        }
    </div >
};

export default CourseParticipantStatistics;