import {
  DataGridPremium as DataGrid,
  GridToolbar,
} from "@mui/x-data-grid-premium";
import { useState, useMemo, useCallback, useEffect } from "react";
import DotMenu from "../DotMenu";
import { IconAndLabelColumnHeader } from "../../utils/dataGrid";
import { CustomNoRowsOverlay } from "../../utils/StyledGridOverlay";
import { dataGridLang } from "../MemToolBar";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DomainIcon from "@mui/icons-material/Domain";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import WarningIcon from "@mui/icons-material/Warning";
import OrderFormBase from "./OrderFormBase";
import Translate from "../../utils/Translate";
import { useApi } from "../../utils/Api";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { formatDate } from "../../utils/dateTimeFormat";
import { useNavigate } from "react-router-dom";
import usePagination from "../../utils/pagination";
import { accessKeys, hasAccess } from "../../utils/userAccess";

const orderStatus = {
  missingInfo: 9,
  registered: 10,
  picked: 40,
  finished: 90,
};

const getStatusFilterOptions = () =>
  [orderStatus.missingInfo, orderStatus.picked, orderStatus.finished].map(
    (status) => ({
      value: status,
      label: Translate.getOrderStatus(status),
    })
  );

export default function OrdersGrid({ actorId, onLoaded }) {
  const localizedTextsMap = dataGridLang();
  const api = useApi();

  const breakpoint = actorId ? 800 : 900;
  const theme = useTheme();
  const isBigScreen = useMediaQuery(theme.breakpoints.up(breakpoint));
  const gridClassName = isBigScreen ? "stdVisible dgDynIconText" : "dgSmall";

  const navigate = useNavigate();
  const navigateToCompany = (id) => {
    navigate(`/dashboard/company/${id}`, { replace: false });
  };

  const loadOrders = useCallback(
    async (paginationRequest) => {
      const url = actorId
        ? `${process.env.REACT_APP_MAIN_URL}actors/${actorId}/orders/get-page`
        : `${process.env.REACT_APP_MAIN_URL}orders/get-page`;

      const response = await api.fetch(url, paginationRequest, "POST");

      if (onLoaded) {
        onLoaded();
      }

      if (response && response.isSuccessful) {
        return { rows: response.rows, rowCount: response.rowCount };
      }
    },
    [api, onLoaded, actorId]
  );

  const { localUpdateRows, ...pagination } = usePagination(loadOrders, {
    field: "regDate",
    sort: "desc",
  });

  const finishOrder = useCallback(
    async (orderId) => {
      const response = await api.fetch(
        `${process.env.REACT_APP_MAIN_URL}orders/${orderId}/finish`,
        true,
        "POST"
      );

      if (response.isSuccessful) {
        localUpdateRows((prevOrders) => {
          const index = prevOrders.findIndex((o) => o.orderId === orderId);
          return index >= 0 ? prevOrders.with(index, response) : prevOrders;
        });
      }
    },
    [api, localUpdateRows]
  );

  const getDotMenuActions = useCallback(
    (orderId, status) => {
      const menudata = [];
      menudata.push({
        icon: <EditIcon />,
        text: Translate.get("EditOrder"),
        customClass: hasAccess(accessKeys.isMASystem)
          ? "bigCourseDialog"
          : "mediumCourseDialog",
        component: <OrderFormBase orderId={orderId} />,
      });
      menudata.push({
        icon: <DoneIcon />,
        disabled: status === orderStatus.finished,
        text: Translate.get("FinishOrder"),
        onClick: () => finishOrder(orderId),
      });
      return menudata;
    },
    [finishOrder]
  );

  const columns = useMemo(
    () => [
      {
        field: "regDate",
        type: "date",
        flex: 0.7,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Date"),
        valueGetter: (params) => new Date(params.value),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={CalendarTodayIcon}
            label={Translate.get("Date")}
          />
        ),
        renderCell: (params) => formatDate(params.value),
      },
      ...(isBigScreen
        ? [
            {
              field: "orderNo",
              flex: 1,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("OrderNumber"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={FormatListNumberedIcon}
                  label={Translate.get("OrderNumber")}
                />
              ),
            },
            {
              field: "customerOrderNo",
              flex: 1,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("PurchaseOrderNo"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={ListAltIcon}
                  label={Translate.get("PurchaseOrderNo")}
                />
              ),
            },
          ]
        : []),
      ...(!actorId
        ? [
            {
              field: "actorName",
              flex: 1,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("Company"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={DomainIcon}
                  label={Translate.get("Company")}
                />
              ),
            },
          ]
        : []),
      {
        field: "partDescription",
        flex: 1,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Product"),
        // Currently we only support a single orderLine
        valueGetter: (params) => params.row.orderLines[0]?.part?.description,
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={ShoppingBasketIcon}
            label={Translate.get("Product")}
          />
        ),
      },
      ...(isBigScreen
        ? [
            {
              field: "quantity",
              flex: 0.5,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("Quantity"),
              // Currently we only support a single orderLine
              valueGetter: (params) => params.row.orderLines[0]?.qty,
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={ScatterPlotIcon}
                  label={Translate.get("Quantity")}
                />
              ),
            },
          ]
        : []),
      {
        type: "singleSelect",
        valueOptions: getStatusFilterOptions(),
        field: "status",
        flex: 0.6,
        disableColumnMenu: true,
        headerClassName: "hideSeparator",
        headerName: Translate.get("Status"),
        renderHeader: () => (
          <IconAndLabelColumnHeader
            icon={CheckCircleIcon}
            label={Translate.get("Status")}
          />
        ),
        renderCell: (params) => (
          <Box>
            {params.value < orderStatus.registered && (
              <Tooltip
                arrow
                className="iconbox warning"
                title={Translate.getOrderStatus(params.value)}
              >
                <WarningIcon />
              </Tooltip>
            )}
            {params.value >= orderStatus.picked && (
              <Tooltip
                arrow
                className="iconbox blue"
                title={Translate.getOrderStatus(orderStatus.picked)}
              >
                <LocalShippingIcon />
              </Tooltip>
            )}
            {params.value === orderStatus.finished && (
              <Tooltip
                arrow
                className="iconbox success"
                title={Translate.getOrderStatus(orderStatus.finished)}
              >
                <DoneIcon />
              </Tooltip>
            )}
          </Box>
        ),
      },
      ...(isBigScreen
        ? [
            {
              field: "regActorName",
              flex: 0.8,
              disableColumnMenu: true,
              headerClassName: "hideSeparator",
              headerName: Translate.get("RegisteredBy"),
              renderHeader: () => (
                <IconAndLabelColumnHeader
                  icon={AccountCircleIcon}
                  label={Translate.get("RegisteredBy")}
                />
              ),
            },
          ]
        : []),
      {
        field: "dotMenu",
        flex: 0.3,
        headerName: "",
        sortable: false,
        disableColumnMenu: true,
        filterable: false,
        hideable: false,
        headerClassName: "hideSeparator",
        align: "right",
        renderCell: (params) => (
          <DotMenu
            content={getDotMenuActions(params.row.orderId, params.row.status)}
            horizontal
          />
        ),
      },
    ],
    [getDotMenuActions, actorId, isBigScreen]
  );

  return (
    <DataGrid
      className={gridClassName}
      columns={columns}
      disableSelectionOnClick
      getRowId={(row) => row.orderId}
      autoHeight={true}
      components={{
        Toolbar: GridToolbar,
        NoRowsOverlay: CustomNoRowsOverlay(Translate.get("EmptyHere")),
      }}
      componentsProps={{
        columnsPanel: { className: "customColumnPanel" },
        filterPanel: { className: "customfilterPanel" },
        panel: { className: "customPanel" },
      }}
      localeText={localizedTextsMap}
      disableDensitySelector={true}
      disableColumnMenu
      onRowClick={(params) => !actorId && navigateToCompany(params.row.actorId)}
      {...pagination}
    />
  );
}
