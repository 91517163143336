export function getUniqueValues(data, column) {
    let s = new Set();
    data.forEach(element => {
        let item = element[column];
        if (item != null) {
            s.add(element[column].trim());
        }
    });
    return Array.from(s);
}

export function getUniqueValuesByCondition(data, filterColumn, filterValue, uniqueColumn) {
    let s = new Set();
    data.forEach(element => {
        if (element[filterColumn] === filterValue) {
            var v = element[uniqueColumn];
            if (v != null) {
                var splits = v.split(",")
                splits.forEach(el => {
                    if (el !== "") {
                        s.add(el.trim())
                    }
                });
            }
        }
    });
    return Array.from(s);
}