import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useEffect, useState } from "react";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { isCoursePartType, partType } from "../../utils/part";
import ButtonWithSpinner from "../ButtonWithSpinner";
import StUnitField from "./StUnitField";

// We use IDs like "dummyId1" or crypto.RandomUUID() here in frontend for new orderLines
function isDummyOrderLineId(orderLineId) {
  return typeof orderLineId !== "number";
}

export default function OrderLineDialog({
  orderLine,
  onSave,
  onCancel,
  partSearch,
  companyActorId,
  getPrice,
  getStUnits,
  currency,
}) {
  if (orderLine.partId && !orderLine.part) {
    throw new Error("Missing part");
  }

  const [stUnits, setStUnits] = useState([]);
  const [isExistingOrderLine] = useState(
    !isDummyOrderLineId(orderLine.orderLineId)
  );
  const [existingDiscount, setExistingDiscount] = useState(
    orderLine.discount ?? 0
  );
  const [updatedOrderLine, setUpdatedOrderLine] = useState({
    ...orderLine,
    qty: orderLine.qty ?? 1,
    discount: orderLine.discount ?? 0,
    isFixedTotalPrice: orderLine.isFixedTotalPrice ?? false,
    saveFixedPrice: orderLine.saveFixedPrice ?? false,
    saveFixedPercent: orderLine.saveFixedPercent ?? false,
  });

  useEffect(() => {
    if (orderLine && orderLine.partId > 0) {
      getStUnits(orderLine.partId).then((response) =>
        setStUnits(
          response.isSuccessful ? response.stUnits.sort(sortStUnits) : []
        )
      );
    }
  }, [orderLine, getStUnits]);

  function isValid() {
    return (
      updatedOrderLine.partId &&
      updatedOrderLine.part &&
      updatedOrderLine.qty > 0 &&
      updatedOrderLine.discountedPcsPrice >= 0 &&
      updatedOrderLine.discount >= 0 &&
      updatedOrderLine.discount <= 1 &&
      updatedOrderLine.price >= 0
      // updatedOrderLine.vat
    );
  }

  function sortStUnits(a, b) {
    if (!a.blocked && b.blocked) {
      return -1;
    } else if (a.blocked && !b.blocked) {
      return 1;
    }

    if (a.isUnlimitedQty && !b.isUnlimitedQty) {
      return -1;
    } else if (!a.isUnlimitedQty && b.isUnlimitedQty) {
      return 1;
    }

    return b.qty - a.qty;
  }

  async function handlePartSelected(selectedPart) {
    const qty = updatedOrderLine.qty;
    const [partResponse, stUnitsResponse] = await Promise.all([
      getPrice(selectedPart.partId, qty, companyActorId),
      getStUnits(selectedPart.partId),
    ]);
    if (partResponse.isSuccessful) {
      setUpdatedOrderLine((prev) => ({
        ...prev,
        partId: selectedPart.partId,
        part: selectedPart,
        discountedPcsPrice: partResponse.price,
        discount: partResponse.discount,
        originalPcsPrice: partResponse.originalPrice,
        price: partResponse.price * qty,
        vat: partResponse.vatPercent,
      }));
      setExistingDiscount(partResponse.discount);
    }
    setStUnits(
      stUnitsResponse.isSuccessful
        ? stUnitsResponse.stUnits.sort(sortStUnits)
        : []
    );
  }

  function handleQtyChange(newValue) {
    const newPrice = newValue * updatedOrderLine.discountedPcsPrice;
    setUpdatedOrderLine((prev) => ({
      ...prev,
      qty: Number(newValue),
      price: newPrice,
    }));
  }

  function handlePcsPriceChange(newValue) {
    const newPrice = updatedOrderLine.qty * newValue;
    const newDiscount = Number(
      (1 - newValue / updatedOrderLine.originalPcsPrice).toFixed(19)
    );

    setUpdatedOrderLine((prev) => ({
      ...prev,
      discountedPcsPrice: Number(newValue),
      price: newPrice,
      discount: newDiscount,
    }));

    if (newDiscount === 0) {
      // Reset checkboxes since there is no longer any discount
      setUpdatedOrderLine((prev) => ({
        ...prev,
        saveFixedPrice: false,
        saveFixedPercent: false,
      }));
    }
  }

  function handleDiscountChange(newValue) {
    const newPcsPrice = Number(
      (updatedOrderLine.originalPcsPrice * (1 - newValue)).toFixed(6)
    );
    const newPrice = newPcsPrice * updatedOrderLine.qty;

    setUpdatedOrderLine((prev) => ({
      ...prev,
      discount: Number(newValue),
      discountedPcsPrice: newPcsPrice,
      price: newPrice,
    }));

    if (newValue == 0) {
      // Reset checkboxes since there is no longer any discount
      setUpdatedOrderLine((prev) => ({
        ...prev,
        saveFixedPrice: false,
        saveFixedPercent: false,
      }));
    }
  }

  function handleTotalPriceChange(newValue) {
    if (!updatedOrderLine.isFixedTotalPrice) {
      throw new Error();
    }

    const newPcsPrice = Number((newValue / updatedOrderLine.qty).toFixed(6));
    setUpdatedOrderLine((prev) => ({
      ...prev,
      discountedPcsPrice: newPcsPrice,
      price: Number(newValue),
    }));

    if (newPcsPrice === updatedOrderLine.originalPcsPrice) {
      // Reset checkboxes since there is no longer any discount
      setUpdatedOrderLine((prev) => ({
        ...prev,
        saveFixedPrice: false,
        saveFixedPercent: false,
      }));
    }
  }

  async function resetOrderLinePrices() {
    if (isExistingOrderLine) {
      setUpdatedOrderLine((prev) => ({
        ...prev,
        discountedPcsPrice: orderLine.discountedPcsPrice,
        discount: orderLine.discount,
        price: orderLine.price,
      }));
    } else {
      const response = await getPrice(
        updatedOrderLine.partId,
        updatedOrderLine.qty,
        companyActorId
      );
      if (response.isSuccessful) {
        setUpdatedOrderLine((prev) => ({
          ...prev,
          discountedPcsPrice: response.price,
          discount: response.discount,
          price: response.price * prev.qty,
        }));
        setExistingDiscount(response.discount);
      }
    }
  }

  async function handleIsFixedPriceChange(newValue) {
    if (!updatedOrderLine.partId) {
      return;
    }

    if (newValue) {
      const newPcsPrice = Number(
        (updatedOrderLine.price / updatedOrderLine.qty).toFixed(6)
      );
      setUpdatedOrderLine((prev) => ({
        ...prev,
        isFixedTotalPrice: newValue,
        discount: 0,
        discountedPcsPrice: newPcsPrice,
        saveFixedPrice: false,
        saveFixedPercent: false,
      }));
    } else {
      await resetOrderLinePrices();
      setUpdatedOrderLine((prev) => ({
        ...prev,
        isFixedTotalPrice: newValue,
      }));
    }
  }

  function isQtyUpdateAllowed() {
    // Other partTypes are managed elsewhere. For example, the orderLine.qty for a courseBooking
    // depends on the booked number of students, and for a plasticCardPrintJob it depends on the
    // number of cards that were sent in. So it doesn't make sense to change the qty here.
    const currentPartType = updatedOrderLine.part?.partType;
    return (
      currentPartType === partType.book ||
      currentPartType === partType.resellableELearningLicense ||
      currentPartType === partType.resellableExtraFinalExam ||
      currentPartType === partType.storeGoods
    );
  }

  function hasChanges() {
    return (
      (orderLine &&
        updatedOrderLine &&
        orderLine.partId !== updatedOrderLine.partId) ||
      orderLine.price !== updatedOrderLine.price ||
      orderLine.discountedPcsPrice !== updatedOrderLine.discountedPcsPrice ||
      (orderLine.discount ?? 0) !== updatedOrderLine.discount ||
      (orderLine.qty ?? 1) !== updatedOrderLine.qty ||
      (orderLine.isFixedTotalPrice ?? false) !==
        updatedOrderLine.isFixedTotalPrice
    );
  }

  console.log("updatedOrderLine", updatedOrderLine);

  return (
    <Dialog open={true} className="mediumCourseDialog">
      <DialogTitle>{Translate.get("OrderLine")}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item marginTop={2}>
            <h4>{Translate.get("PartLabel")}</h4>
          </Grid>
          <Grid item>
            <AutocompleteSearchField
              disabled={isExistingOrderLine}
              label={Translate.get("PartLabel")}
              value={updatedOrderLine.part ?? ""}
              groupBy={(option) => Translate.getPartTypePlural(option.partType)}
              onValueChange={(selectedPart) => handlePartSelected(selectedPart)}
              getOptionLabel={(option) => option?.description ?? ""}
              renderOption={(option) => (
                <Grid container>
                  <Grid item xs={3}>
                    <Typography
                      noWrap
                      variant="body2"
                      sx={{
                        color: "text.secondary",
                      }}
                    >
                      {option.partNo ?? ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Box>{option.description}</Box>
                  </Grid>
                </Grid>
              )}
              keyPropName="partId"
              disableAddAsNew={true}
              requireSelection={true}
              search={async (searchText) => {
                const results = await partSearch(searchText);
                // Sorting needed for groupBy of search results
                return results.sort((a, b) => {
                  const partTypeSort = Translate.getPartTypePlural(
                    a.partType
                  ).localeCompare(Translate.getPartTypePlural(b.partType));
                  return partTypeSort != 0
                    ? partTypeSort
                    : a.description.localeCompare(b.description);
                });
              }}
              textFieldProps={{
                required: true,
                error: false,
                helperText: "",
              }}
            />
          </Grid>
          {updatedOrderLine.partId && (
            <Grid item>
              <TextField
                fullWidth
                required
                disabled={
                  !updatedOrderLine.partId ||
                  !updatedOrderLine.canUpdate ||
                  !isQtyUpdateAllowed()
                }
                label={Translate.get("Quantity")}
                value={isNaN(updatedOrderLine.qty) ? "" : updatedOrderLine.qty}
                onChange={(event) => handleQtyChange(event.target.value)}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "number",
                  min: 1,
                }}
              />
            </Grid>
          )}

          {updatedOrderLine.partId && (
            <Grid
              container
              item
              spacing={2}
              marginTop={2}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <h4>{Translate.get("Price")}</h4>
              </Grid>
              <Grid item>
                <FormControlLabel
                  sx={{ marginBottom: "-10px" }}
                  control={
                    <Checkbox
                      disabled={
                        !updatedOrderLine.partId || !updatedOrderLine.canUpdate
                      }
                      checked={updatedOrderLine.isFixedTotalPrice}
                      onChange={(event) =>
                        handleIsFixedPriceChange(event.target.checked)
                      }
                    />
                  }
                  label={Translate.get("FixedPrice")}
                />
              </Grid>
            </Grid>
          )}

          {updatedOrderLine.partId && !updatedOrderLine.isFixedTotalPrice && (
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  disabled={
                    !updatedOrderLine.partId || !updatedOrderLine.canUpdate
                  }
                  label={Translate.get("PricePerUnit")}
                  value={
                    isNaN(updatedOrderLine.discountedPcsPrice)
                      ? ""
                      : updatedOrderLine.discountedPcsPrice.toFixed(2)
                  }
                  onChange={(event) => handlePcsPriceChange(event.target.value)}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    type: "number",
                    min: 0,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currency}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  required
                  disabled={
                    !updatedOrderLine.partId || !updatedOrderLine.canUpdate
                  }
                  label={Translate.get("Discount")}
                  value={
                    isNaN(updatedOrderLine.discount)
                      ? 0
                      : (updatedOrderLine.discount * 100).toFixed(2)
                  }
                  onChange={(event) =>
                    handleDiscountChange(
                      isNaN(event.target.value) ? 0 : event.target.value / 100
                    )
                  }
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    type: "number",
                    min: 0,
                    max: 100,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          )}

          {updatedOrderLine.partId && (
            <Grid item>
              <TextField
                fullWidth
                required={updatedOrderLine.isFixedTotalPrice}
                disabled={
                  !updatedOrderLine.isFixedTotalPrice ||
                  !updatedOrderLine.partId ||
                  !updatedOrderLine.canUpdate
                }
                label={Translate.get("TotalAmount")}
                value={
                  isNaN(updatedOrderLine.price)
                    ? ""
                    : updatedOrderLine.price.toFixed(2)
                }
                onChange={(event) =>
                  handleTotalPriceChange(
                    isNaN(event.target.value) ? 0 : event.target.value
                  )
                }
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  type: "number",
                  min: 0,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

          {updatedOrderLine.partId &&
            updatedOrderLine.discount > 0 &&
            existingDiscount !== updatedOrderLine.discount && (
              <Grid item container direction="column">
                <Grid item>
                  <FormControlLabel
                    sx={{ marginBottom: "-10px" }}
                    control={
                      <Checkbox
                        disabled={
                          !updatedOrderLine.discount ||
                          !updatedOrderLine.canUpdate
                        }
                        checked={updatedOrderLine.saveFixedPrice}
                        onChange={(event) =>
                          setUpdatedOrderLine((prev) => ({
                            ...prev,
                            saveFixedPrice: event.target.checked,
                            saveFixedPercent: false,
                          }))
                        }
                      />
                    }
                    label={Translate.get("SaveDiscountFixedPrice")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    sx={{ marginBottom: "-10px" }}
                    control={
                      <Checkbox
                        disabled={
                          !updatedOrderLine.discount ||
                          !updatedOrderLine.canUpdate
                        }
                        checked={updatedOrderLine.saveFixedPercent}
                        onChange={(event) =>
                          setUpdatedOrderLine((prev) => ({
                            ...prev,
                            saveFixedPercent: event.target.checked,
                            saveFixedPrice: false,
                          }))
                        }
                      />
                    }
                    label={Translate.get("SaveDiscountFixedPercent")}
                  />
                </Grid>
              </Grid>
            )}

          {stUnits && stUnits.length > 0 && (
            <Grid
              container
              item
              spacing={2}
              marginTop={2}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <h4>{Translate.get("StorageInfoLabel")}</h4>
              </Grid>

              <Grid item container spacing={1}>
                {stUnits.map((s) => (
                  <Grid item key={s.stUnitId}>
                    <StUnitField {...s} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {Translate.get("Cancel")}
        </Button>
        <ButtonWithSpinner
          variant="contained"
          onClick={async () => await onSave(updatedOrderLine)}
          disabled={!isValid() || !updatedOrderLine.canUpdate}
        >
          {Translate.get("Ok")}
        </ButtonWithSpinner>
      </DialogActions>
    </Dialog>
  );
}
