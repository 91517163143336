import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Typography, DialogActions } from '@mui/material';
import Translate from "../../../utils/Translate";
import { Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Grid from '@mui/material/Grid';
import { AddFilter, DeleteFilter, GetFilters } from './MyFilterApi';
import { useApi } from "../../../utils/Api";
import Preloader from '../../Preloader';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { getUniqueValues, getUniqueValuesByCondition } from './MyFilterCommon';

const MyCourseParticipantFilters = ({ open, onClose, data, setFilter }) => {

    const api = useApi();

    const [isLoading, setIsLoading] = useState(true);

    const [licenseGroup, setLicenseGroup] = useState("")
    const [licenseGroupOptions, setLicenseGroupOptions] = useState([]);

    const [company, setCompany] = useState("");
    const [courseParticipantName, setCourseParticipantName] = useState("");
    const [courseCity, setCourseCity] = useState("");

    const [fromDateOperator, setFromDateOperator] = useState("");
    const [fromDate, setFromDate] = useState(dayjs().format('YYYY-MM-DD'));

    const [fromDate2Operator, setFromDate2Operator] = useState("");
    const [fromDate2, setFromDate2] = useState(dayjs().format('YYYY-MM-DD'));

    const [theories, setTheories] = useState([]);
    const [theoryOptions, setTheoryOptions] = useState([]);

    const [practices, setPractices] = useState([]);
    const [practiceOptions, setPracticeOptions] = useState([]);

    const [filters, setFilters] = useState(new Map([]));

    const [selectedFilter, setSelectedFilter] = useState("new");

    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [newFilterName, setNewFilterName] = useState("");

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(async () => {
        let licenseGroupOptions = getUniqueValues(data, "licenseDesc");
        setLicenseGroupOptions(licenseGroupOptions.sort());

        var filters = await GetFilters(3, api, setIsLoading);
        setFilters(filters);

    }, []);



    function handleLicenseGroupChange(event) {
        let licenseGroupDesc = event.target.value
        setLicenseGroup(licenseGroupDesc);

        setTheories([]);
        let theoryOptions = getUniqueValuesByCondition(data, "licenseDesc", licenseGroupDesc, "licenseGroupTheory")
        setTheoryOptions(theoryOptions);

        setPractices([]);
        let practiceOptions = getUniqueValuesByCondition(data, "licenseDesc", licenseGroupDesc, "licenseGroupPractice")
        setPracticeOptions(practiceOptions);
    }

    function handleCompanyChange(event) {
        setCompany(event.target.value);
    }

    function handleCourseParticipantNameChange(event) {
        setCourseParticipantName(event.target.value);
    }

    function handleCourseCityChange(event) {
        setCourseCity(event.target.value);
    }

    function handleFromDateOperatorChange(event) {
        setFromDateOperator(event.target.value)
    }

    function handleFromDateChange(newDate) {
        setFromDate(newDate);
    }

    function handleFromDate2OperatorChange(event) {
        setFromDate2Operator(event.target.value)
    }

    function handleFromDate2Change(newDate) {
        setFromDate2(newDate);
    }

    function handleTheoryChange(event) {
        setTheories(event.target.value);
    }

    function handlePracticeChange(event) {
        setPractices(event.target.value);
    }

    function handleCloseSaveDialog() {
        setNewFilterName("");
        setOpenSaveDialog(false);
    }

    function handleCloseDeleteDialog() {
        setOpenDeleteDialog(false);
    }

    async function handleSaveFilter() {
        let filter = buildExpression();
        let jsFilter = JSON.stringify(filter);

        let reqObj = {
            name: newFilterName,
            filter: jsFilter,
            filterType: 3
        }

        let res = await AddFilter(reqObj, api, setIsLoading);
        if (res === true) {
            handleCloseSaveDialog();
            let filters = await GetFilters(3, api, setIsLoading);
            setFilters(filters);
            setSelectedFilter(reqObj.name);
        }
    }

    async function handleDeleteFilter() {
        let req = {
            name: selectedFilter
        }

        let res = await DeleteFilter(req, api, setIsLoading);
        if (res === true) {
            let filters = await GetFilters(3, api, setIsLoading);
            setFilters(filters);
            setSelectedFilter("new");
            setOpenDeleteDialog(false);
            resetStates();
        }
    }

    function handleFilterClick() {
        let expr = buildExpression();

        buildMuiFilterFromMyFilter(expr);
        onClose();
    }

    function handleFilterChange(event) {
        setSelectedFilter(event.target.value);
        if (event.target.value === "new") {
            resetStates();
            return;
        }

        setFormValuesFromExpr(event.target.value);
    }

    function buildExpression() {
        let arr = [];

        if (licenseGroup !== "") {
            let expr = {
                field: "licenseGroup",
                operator: "equals",
                value: licenseGroup,
            }
            arr.push(expr)
        }

        if (company !== "") {
            let expr = {
                field: "company",
                operator: "contains",
                value: company,
            }
            arr.push(expr)
        }

        if (courseParticipantName !== "") {
            let expr = {
                field: "courseParticipantName",
                operator: "contains",
                value: courseParticipantName,
            }
            arr.push(expr)
        }

        if (courseCity !== "") {
            let expr = {
                field: "courseCity",
                operator: "contains",
                value: courseCity,
            }
            arr.push(expr)
        }

        if (fromDate !== "" && fromDateOperator !== "") {
            let expr = {
                field: "fromDate",
                operator: fromDateOperator,
                value: dayjs(fromDate).format('YYYY-MM-DD'),
            }
            arr.push(expr);
        }

        if (fromDate2 !== "" && fromDate2Operator !== "") {
            let expr = {
                field: "fromDate2",
                operator: fromDate2Operator,
                value: dayjs(fromDate2).format('YYYY-MM-DD'),
            }
            arr.push(expr);
        }

        if (theories.length > 0) {
            let sortedStr = theories.sort().join(", ");

            let expr = {
                field: "theory",
                operator: "contains",
                value: sortedStr,
            }
            arr.push(expr);
        }

        if (practices.length > 0) {
            let sortedStr = practices.sort().join(", ");

            let expr = {
                field: "practice",
                operator: "contains",
                value: sortedStr,
            }
            arr.push(expr);
        }

        return arr;
    }

    function buildMuiFilterFromMyFilter(filters) {
        let exprs = new Map(filters.map(obj => [obj.field, obj]));

        let arr = [];

        if (exprs.has("licenseGroup")) {
            let expr = {
                id: 5,
                field: "licenseDesc",
                operator: exprs.get("licenseGroup").operator,
                value: exprs.get("licenseGroup").value,
                filterName: "myfilter",
            }
            arr.push(expr);
        }

        if (exprs.has("company")) {
            let expr = {
                id: 6,
                field: "companyName",
                operator: exprs.get("company").operator,
                value: exprs.get("company").value,
                filterName: "myfilter",
            }
            arr.push(expr);
        }

        if (exprs.has("courseParticipantName")) {
            let expr = {
                id: 7,
                field: "studentName",
                operator: exprs.get("courseParticipantName").operator,
                value: exprs.get("courseParticipantName").value,
                filterName: "myfilter",
            }
            arr.push(expr);
        }

        if (exprs.has("courseCity")) {
            let expr = {
                id: 8,
                field: "courseCity",
                operator: exprs.get("courseCity").operator,
                value: exprs.get("courseCity").value,
                filterName: "myfilter",
            }
            arr.push(expr);
        }

        if (exprs.has("fromDate")) {
            let expr = {
                id: 9,
                field: "fromDate",
                operator: exprs.get("fromDate").operator,
                value: exprs.get("fromDate").value,
                filterName: "myfilter",
            }
            arr.push(expr);
        }

        if (exprs.has("fromDate2")) {
            let expr = {
                id: 10,
                field: "fromDate",
                operator: exprs.get("fromDate2").operator,
                value: exprs.get("fromDate2").value,
                filterName: "myfilter",
            }
            arr.push(expr);
        }

        if (exprs.has("theory")) {
            let expr = {
                id: 11,
                field: "licenseGroupTheory",
                operator: exprs.get("theory").operator,
                value: exprs.get("theory").value,
                filterName: "myfilter",
            }
            arr.push(expr);
        }

        if (exprs.has("practice")) {
            let expr = {
                id: 12,
                field: "licenseGroupPractice",
                operator: exprs.get("practice").operator,
                value: exprs.get("practice").value,
                filterName: "myfilter",
            }
            arr.push(expr);
        }

        setFilter({ items: arr });
    }

    function setFormValuesFromExpr(filterName) {
        resetStates();

        var filter = filters.get(filterName);
        let expr = new Map(filter.map(obj => [obj.field, obj]));

        if (expr.has("licenseGroup")) {
            setLicenseGroup(expr.get("licenseGroup").value);
        }

        if (expr.has("company")) {
            setCompany(expr.get("company").value);
        }

        if (expr.has("courseParticipantName")) {
            setCourseParticipantName(expr.get("courseParticipantName").value);
        }

        if (expr.has("courseCity")) {
            setCourseCity(expr.get("courseCity").value);
        }

        if (expr.has("fromDate")) {
            setFromDateOperator(expr.get("fromDate").operator);
            setFromDate(expr.get("fromDate").value)
        }

        if (expr.has("fromDate2")) {
            setFromDateOperator(expr.get("fromDate2").operator);
            setFromDate(expr.get("fromDate2").value)
        }

        if (expr.has("licenseGroup") && expr.has("theory")) {
            let compArray = expr.get("theory").value.split(',').map(item => item.trim());
            setTheories(compArray);

            let theoryOptions = getUniqueValuesByCondition(data, "licenseDesc", expr.get("licenseGroup").value, "licenseGroupTheory")
            setTheoryOptions(theoryOptions);
        }

        if (expr.has("licenseGroup") && expr.has("practice")) {
            let compArray = expr.get("practice").value.split(',').map(item => item.trim());
            setPractices(compArray);

            let practiceOptions = getUniqueValuesByCondition(data, "licenseDesc", expr.get("licenseGroup").value, "licenseGroupPractice")
            setPracticeOptions(practiceOptions);
        }
    }

    function resetStates() {
        setLicenseGroup("");
        setCompany("");
        setCourseParticipantName("");
        setCourseCity("");
        setFromDateOperator("");
        setFromDate(dayjs().format('YYYY-MM-DD'));
        setFromDate2Operator("");
        setFromDate2(dayjs().format('YYYY-MM-DD'));
        setTheories([]);
        setPractices([]);
    }

    if (isLoading) {
        return <Preloader />
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {Translate.get("Filter2")}
            </DialogTitle>
            <DialogContent>
                <div style={{ width: "22em" }}>
                    <b><p>{Translate.get("Filter2")}</p></b>

                    <FormControl fullWidth margin="normal">
                        <InputLabel>{Translate.get("ChooseQuickFilter")}</InputLabel>
                        <Select
                            value={selectedFilter}
                            onChange={handleFilterChange}
                            label={Translate.get("ChooseQuickFilter")}
                        >
                            <MenuItem key={1} value="new"><em>{Translate.get("New2")}</em></MenuItem>
                            {Array.from(filters.keys()).map((filter) => (
                                <MenuItem key={filter} value={filter}>
                                    {filter.charAt(0).toUpperCase() + filter.slice(1)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <div style={{ marginTop: "0.1em", marginBottom: "1em", display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenDeleteDialog(true)}
                            style={{ marginRight: "0.2em" }}
                            disabled={selectedFilter === "new"}
                        >
                            <DeleteIcon />
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setOpenSaveDialog(true)}
                        >
                            <SaveIcon />
                        </Button>
                    </div>

                    <b><p style={{ marginBottom: "1em" }}>{Translate.get("Details")}</p></b>

                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="course-group-label">{Translate.get("LicenseGroup")}</InputLabel>
                        <Select
                            labelId="course-group-label"
                            value={licenseGroup}
                            onChange={handleLicenseGroupChange}
                            label={Translate.get("LicenseGroup")}
                        >
                            <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
                            {licenseGroupOptions.map((group, index) => (
                                <MenuItem key={index} value={group}>
                                    {group}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{ marginTop: "1em" }}>
                        <InputLabel id="theory-label">{Translate.get("TheoryCompetence")}</InputLabel>
                        <Select
                            labelId="theory-label"
                            multiple
                            value={theories}
                            onChange={handleTheoryChange}
                            renderValue={(selected) => selected.join(', ')}
                            label={Translate.get("Competences")}
                        >
                            {theoryOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox checked={theories.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant="outlined" style={{ marginTop: "1em" }}>
                        <InputLabel id="practice-label">{Translate.get("PracticalCompetence")}</InputLabel>
                        <Select
                            labelId="practice-label"
                            multiple
                            value={practices}
                            onChange={handlePracticeChange}
                            renderValue={(selected) => selected.join(', ')}
                            label={Translate.get("Competences")}
                        >
                            {practiceOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    <Checkbox checked={practices.indexOf(option) > -1} />
                                    <ListItemText primary={option} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField
                        value={company}
                        onChange={handleCompanyChange}
                        label={Translate.get("Company")}
                        fullWidth
                        style={{ marginTop: "1em" }}
                    >
                    </TextField>

                    <TextField
                        value={courseParticipantName}
                        onChange={handleCourseParticipantNameChange}
                        label={Translate.get("StudentName")}
                        fullWidth
                        style={{ marginTop: "1em" }}
                    >
                    </TextField>

                    <TextField
                        value={courseCity}
                        onChange={handleCourseCityChange}
                        label={Translate.get("City")}
                        fullWidth
                        style={{ marginTop: "1em" }}
                    >
                    </TextField>

                    <Grid container style={{ marginTop: "1em" }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth style={{ paddingRight: "0.1em" }}>
                                <InputLabel id="valid-select-label">{Translate.get("CourseStart")}</InputLabel>
                                <Select
                                    value={fromDateOperator}
                                    onChange={handleFromDateOperatorChange}
                                    labelId="instructor-select-label"
                                    label={Translate.get("CourseStart")}
                                >
                                    <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
                                    <MenuItem value="onOrBefore">{Translate.get("Before")}</MenuItem>
                                    <MenuItem value="onOrAfter">{Translate.get("After")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label={Translate.get("Date")}
                                value={fromDate}
                                onChange={handleFromDateChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginTop: "1em" }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth style={{ paddingRight: "0.1em" }}>
                                <InputLabel id="valid-select-label">{Translate.get("CourseStart")}</InputLabel>
                                <Select
                                    value={fromDate2Operator}
                                    onChange={handleFromDate2OperatorChange}
                                    labelId="instructor-select-label"
                                    label={Translate.get("CourseStart")}
                                >
                                    <MenuItem value=""><em>{Translate.get("None")}</em></MenuItem>
                                    <MenuItem value="onOrBefore">{Translate.get("Before")}</MenuItem>
                                    <MenuItem value="onOrAfter">{Translate.get("After")}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <DatePicker
                                label={Translate.get("Date")}
                                value={fromDate2}
                                onChange={handleFromDate2Change}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div style={{ marginTop: "2em", display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
                    <Button variant="contained" onClick={handleFilterClick}>
                        {Translate.get("Filter")}
                    </Button>
                </div>

                <Dialog open={openSaveDialog} onClose={handleCloseSaveDialog}>
                    <DialogTitle>{Translate.get("SaveQuickFilter")}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            {Translate.get("ChooseNameForQuickFilter")}
                        </Typography>
                        <TextField
                            fullWidth
                            label={Translate.get("Name")}
                            variant="outlined"
                            value={newFilterName}
                            onChange={(e) => setNewFilterName(e.target.value)}
                            inputProps={{ maxLength: 50 }}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSaveDialog} variant="outlined">
                            Avbryt
                        </Button>
                        <Button onClick={handleSaveFilter} variant="contained">
                            {Translate.get("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                    <DialogTitle>{Translate.get("RemoveQuickFilter")}</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1" gutterBottom>
                            {Translate.get("SureDeleteQuickFilter")}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} variant="outlined">
                            Avbryt
                        </Button>
                        <Button onClick={() => handleDeleteFilter()} variant="contained">
                            {Translate.get("Delete")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContent>
        </Dialog>
    );
};

export default MyCourseParticipantFilters;