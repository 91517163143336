import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import Translate from "../../utils/Translate";
import { useCallback, useEffect, useState } from "react";
import AutocompleteSearchField from "../AutocompleteSearchField";
import { useApi } from "../../utils/Api";

export default function AddressDialog({
  street1,
  street2,
  zipCode,
  city,
  country,
  onSave,
  onCancel,
  addressTypeId,
}) {
  const [countries, setCountries] = useState([]);
  const [updatedAddress, setUpdatedAddress] = useState({
    street1,
    street2,
    zipCode,
    city,
    country,
  });

  const api = useApi();

  const countrySearch = useCallback(
    async (searchText) => {
      const lang = localStorage.lang ?? 29;
      const search = encodeURIComponent(searchText);
      const url = `${process.env.REACT_APP_MAIN_URL}countries?searchText=${search}&languageCode=${lang}`;
      const result = await api.fetch(url, false, "GET");
      result.sort((a, b) =>
        a.alpha2 === "SE"
          ? -1
          : b.alpha2 === "SE"
          ? 1
          : a.name.localeCompare(b.name)
      );
      return result;
    },
    [api]
  );

  useEffect(() => {
    countrySearch("").then((result) => setCountries(result));
  }, [countrySearch]);

  return (
    <Dialog open={true} className="smallCourseDialog">
      <DialogTitle>{Translate.getActorAddressType(addressTypeId)}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2} marginTop={0.1}>
          <Grid item>
            <TextField
              fullWidth
              value={updatedAddress.street1}
              InputProps={{ spellCheck: false }}
              onChange={(event) =>
                setUpdatedAddress((prev) => ({
                  ...prev,
                  street1: event.target.value,
                }))
              }
              label={Translate.get("Address1")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={updatedAddress.street2}
              InputProps={{ spellCheck: false }}
              onChange={(event) =>
                setUpdatedAddress((prev) => ({
                  ...prev,
                  street2: event.target.value,
                }))
              }
              label={Translate.get("Address2")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={5}>
              <TextField
                fullWidth
                value={updatedAddress.zipCode}
                InputProps={{ spellCheck: false }}
                onChange={(event) =>
                  setUpdatedAddress((prev) => ({
                    ...prev,
                    zipCode: event.target.value,
                  }))
                }
                label={Translate.get("ZipCode")}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                fullWidth
                value={updatedAddress.city}
                InputProps={{ spellCheck: false }}
                onChange={(event) =>
                  setUpdatedAddress((prev) => ({
                    ...prev,
                    city: event.target.value,
                  }))
                }
                label={Translate.get("AddressCity")}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
          </Grid>
          <Grid item>
            {/* Some addresses will have real country names, others have some other kind of data.
                Here we show a list of existing countries if the value matches one or is empty.
                But for all existing addresses with other content we will show a text field. */}
            {!updatedAddress.country?.trim() ||
            countries.find((c) => c.name === updatedAddress.country) ? (
              <AutocompleteSearchField
                requireSelection
                disableAddAsNew
                label={Translate.get("Country")}
                defaultOptions={countries}
                value={
                  updatedAddress.country
                    ? countries.find((c) => c.name === updatedAddress.country)
                    : ""
                }
                onValueChange={(option) => {
                  setUpdatedAddress((prev) => ({
                    ...prev,
                    country: option?.name,
                  }));
                }}
                getOptionLabel={(option) =>
                  option ? `${option.name} (${option.alpha2})` : ""
                }
                renderOption={(option) => `${option.name} (${option.alpha2})`}
                keyPropName="name"
                search={countrySearch}
                textFieldProps={{
                  required: false,
                }}
                hideOptionsFilter={
                  updatedAddress.country &&
                  ((option) => option.name === updatedAddress.country.trim())
                }
              />
            ) : (
              <TextField
                fullWidth
                value={updatedAddress.country}
                onChange={(event) =>
                  setUpdatedAddress((prev) => ({
                    ...prev,
                    country: event.target.value,
                  }))
                }
                label={Translate.get("Country")}
                inputProps={{ maxLength: 50 }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCancel}>
          {Translate.get("Cancel")}
        </Button>
        <Button variant="contained" onClick={() => onSave(updatedAddress)}>
          {Translate.get("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
