import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Typography, DialogActions } from '@mui/material';
import Translate from "../../../utils/Translate";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import { AddFilter, DeleteFilter, GetFilters } from './MyFilterApi';
import { useApi } from "../../../utils/Api";
import Preloader from '../../Preloader';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

const MyContactFilters = ({ open, onClose, data, setFilter }) => {

  const api = useApi();

  const [isLoading, setIsLoading] = useState(false);

  const [contactName, setContactName] = useState("")
  const [contactEmail, setContactEmail] = useState("");
  const [company, setCompany] = useState("");
  const [companyZipCode, setCompanyZipCode] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [contactCity, setContactCity] = useState("");

  const [filters, setFilters] = useState(new Map([]));

  const [selectedFilter, setSelectedFilter] = useState("new");

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [newFilterName, setNewFilterName] = useState("");

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(async () => {
    var filters = await GetFilters(5, api, setIsLoading);
    setFilters(filters);
  }, []);


  function handleContactNameChange(event) {
    setContactName(event.target.value);
  }

  function handleContactEmailChange(event) {
    setContactEmail(event.target.value);
  }

  function handleCompanyChange(event) {
    setCompany(event.target.value);
  }

  function handleCompanyZipCodeChange(event) {
    setCompanyZipCode(event.target.value);
  }

  function handleCompanyCityChange(event) {
    setCompanyCity(event.target.value);
  }

  function handleContactCityChange(event) {
    setContactCity(event.target.value);
  }

  function handleCloseSaveDialog() {
    setNewFilterName("");
    setOpenSaveDialog(false);
  }

  function handleCloseDeleteDialog() {
    setOpenDeleteDialog(false);
  }

  async function handleSaveFilter() {
    let filter = buildExpression();
    let jsFilter = JSON.stringify(filter);

    let reqObj = {
      name: newFilterName,
      filter: jsFilter,
      filterType: 5
    }

    let res = await AddFilter(reqObj, api, setIsLoading);
    if (res === true) {
      handleCloseSaveDialog();
      let filters = await GetFilters(5, api, setIsLoading);
      setFilters(filters);
      setSelectedFilter(reqObj.name);
    }
  }

  async function handleDeleteFilter() {
    let req = {
      name: selectedFilter
    }

    let res = await DeleteFilter(req, api, setIsLoading);
    if (res === true) {
      let filters = await GetFilters(5, api, setIsLoading);
      setFilters(filters);
      setSelectedFilter("new");
      setOpenDeleteDialog(false);
      resetStates();
    }
  }

  function handleFilterClick() {
    let expr = buildExpression();

    buildMuiFilterFromMyFilter(expr);
    onClose();
  }

  function handleFilterChange(event) {
    setSelectedFilter(event.target.value);
    if (event.target.value === "new") {
      resetStates();
      return;
    }

    setFormValuesFromExpr(event.target.value);
  }

  function buildExpression() {
    let arr = [];

    if (contactName !== "") {
      let expr = {
        field: "contactName",
        operator: "contains",
        value: contactName,
      }
      arr.push(expr)
    }

    if (contactEmail !== "") {
      let expr = {
        field: "contactEmail",
        operator: "contains",
        value: contactEmail,
      }
      arr.push(expr)
    }

    if (company !== "") {
      let expr = {
        field: "company",
        operator: "contains",
        value: company,
      }
      arr.push(expr)
    }

    if (companyZipCode !== "") {
      let expr = {
        field: "companyZipCode",
        operator: "contains",
        value: companyZipCode,
      }
      arr.push(expr)
    }

    if (companyCity !== "") {
      let expr = {
        field: "companyCity",
        operator: "contains",
        value: companyCity,
      }
      arr.push(expr)
    }

    if (contactCity !== "") {
      let expr = {
        field: "contactCity",
        operator: "contains",
        value: contactCity,
      }
      arr.push(expr)
    }

    return arr;
  }

  function buildMuiFilterFromMyFilter(filters) {
    let exprs = new Map(filters.map(obj => [obj.field, obj]));

    let arr = [];

    if (exprs.has("contactName")) {
      let expr = {
        id: 5,
        field: "actorName",
        operator: exprs.get("contactName").operator,
        value: exprs.get("contactName").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("contactEmail")) {
      let expr = {
        id: 6,
        field: "actorEmail",
        operator: exprs.get("contactEmail").operator,
        value: exprs.get("contactEmail").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("company")) {
      let expr = {
        id: 7,
        field: "parentName",
        operator: exprs.get("company").operator,
        value: exprs.get("company").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("companyZipCode")) {
      let expr = {
        id: 8,
        field: "parentZipCode",
        operator: exprs.get("companyZipCode").operator,
        value: exprs.get("companyZipCode").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("companyCity")) {
      let expr = {
        id: 9,
        field: "parentCity",
        operator: exprs.get("companyCity").operator,
        value: exprs.get("companyCity").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    if (exprs.has("contactCity")) {
      let expr = {
        id: 10,
        field: "city",
        operator: exprs.get("contactCity").operator,
        value: exprs.get("contactCity").value,
        filterName: "myfilter",
      }
      arr.push(expr);
    }

    setFilter({ items: arr });
  }

  function setFormValuesFromExpr(filterName) {
    resetStates();

    var filter = filters.get(filterName);
    let expr = new Map(filter.map(obj => [obj.field, obj]));

    if (expr.has("contactName")) {
      setContactName(expr.get("contactName").value);

    }

    if (expr.has("contactEmail")) {
      setContactEmail(expr.get("contactEmail").value);
    }

    if (expr.has("company")) {
      setCompany(expr.get("company").value);
    }

    if (expr.has("companyZipCode")) {
      setCompanyZipCode(expr.get("companyZipCode").value);
    }

    if (expr.has("companyCity")) {
      setCompanyCity(expr.get("companyCity").value);
    }

    if (expr.has("contactCity")) {
      setContactCity(expr.get("contactCity").value);
    }
  }

  function resetStates() {
    setContactName("");
    setContactEmail("");
    setCompany("");
    setCompanyZipCode("");
    setCompanyCity("");
    setContactCity("");
  }

  if (isLoading) {
    return <Preloader />
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {Translate.get("Filter2")}
      </DialogTitle>
      <DialogContent>
        <div style={{ width: "22em" }}>
          <b><p>{Translate.get("Filter2")}</p></b>

          <FormControl fullWidth margin="normal">
            <InputLabel>{Translate.get("ChooseQuickFilter")}</InputLabel>
            <Select
              value={selectedFilter}
              onChange={handleFilterChange}
              label={Translate.get("ChooseQuickFilter")}
            >
              <MenuItem key={1} value="new"><em>{Translate.get("New2")}</em></MenuItem>
              {Array.from(filters.keys()).map((filter) => (
                <MenuItem key={filter} value={filter}>
                  {filter.charAt(0).toUpperCase() + filter.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ marginTop: "0.1em", marginBottom: "1em", display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              onClick={() => setOpenDeleteDialog(true)}
              style={{ marginRight: "0.2em" }}
              disabled={selectedFilter === "new"}
            >
              <DeleteIcon />
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpenSaveDialog(true)}
            >
              <SaveIcon />
            </Button>
          </div>

          <b><p style={{ marginBottom: "1em" }}>{Translate.get("Details")}</p></b>

          <TextField
            value={contactName}
            onChange={handleContactNameChange}
            label={Translate.get("Contact")}
            fullWidth
          >
          </TextField>

          <TextField
            value={contactEmail}
            onChange={handleContactEmailChange}
            label={Translate.get("Contact") + " (" + Translate.get("Email") + ")"}
            fullWidth
            style={{ marginTop: "1em" }}
          >
          </TextField>

          <TextField
            value={company}
            onChange={handleCompanyChange}
            label={Translate.get("Company")}
            fullWidth
            style={{ marginTop: "1em" }}
          >
          </TextField>

          <TextField
            value={companyZipCode}
            onChange={handleCompanyZipCodeChange}
            label={Translate.get("Company") + " " + Translate.get("ZipCode")}
            fullWidth
            style={{ marginTop: "1em" }}
          >
          </TextField>

          <TextField
            value={companyCity}
            onChange={handleCompanyCityChange}
            label={Translate.get("Company") + " " + Translate.get("City")}
            fullWidth
            style={{ marginTop: "1em" }}
          >
          </TextField>

          <TextField
            value={contactCity}
            onChange={handleContactCityChange}
            label={Translate.get("Contact") + " " + Translate.get("City")}
            fullWidth
            style={{ marginTop: "1em" }}
          >
          </TextField>
        </div>

        <div style={{ marginTop: "2em", display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onClose}>{Translate.get("Cancel")}</Button>
          <Button variant="contained" onClick={handleFilterClick}>
            {Translate.get("Filter")}
          </Button>
        </div>

        <Dialog open={openSaveDialog} onClose={handleCloseSaveDialog}>
          <DialogTitle>{Translate.get("SaveQuickFilter")}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {Translate.get("ChooseNameForQuickFilter")}
            </Typography>
            <TextField
              fullWidth
              label={Translate.get("Name")}
              variant="outlined"
              value={newFilterName}
              onChange={(e) => setNewFilterName(e.target.value)}
              inputProps={{ maxLength: 50 }}
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSaveDialog} variant="outlined">
              Avbryt
            </Button>
            <Button onClick={handleSaveFilter} variant="contained">
              {Translate.get("Save")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>{Translate.get("RemoveQuickFilter")}</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              {Translate.get("SureDeleteQuickFilter")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} variant="outlined">
              Avbryt
            </Button>
            <Button onClick={() => handleDeleteFilter()} variant="contained">
              {Translate.get("Delete")}
            </Button>
          </DialogActions>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default MyContactFilters;