export async function GetFilters(filterType, api, setIsLoading) {
    try {
        setIsLoading(true);

        let response = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}report/filter/${filterType}`,
            false,
            "GET",
            false,
            true
        );
        if (response.isSuccessful) {
            let filters = parseFiltersToMap(response.filters);
            return filters;
        }
    }
    finally {
        setIsLoading(false);
    }
}

export async function AddFilter(reqObj, api, setIsLoading) {
    setIsLoading(true);

    try {
        let response = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}report/filter`,
            reqObj,
            "POST",
            false,
            true
        );
        if (response.isSuccessful) {
            return true;
        }
    }
    finally {
        setIsLoading(false);
    }
}

export async function DeleteFilter(reqObj, api, setIsLoading) {
    setIsLoading(true);

    try {
        let response = await api.fetch(
            `${process.env.REACT_APP_MAIN_URL}report/filter`,
            reqObj,
            "DELETE",
            false,
            true
        );
        if (response.isSuccessful) {
            return true;
        }
    }
    finally {
        setIsLoading(false);
    }
}

function parseFiltersToMap(filters) {
    const filtersMap = new Map();

    for (const [key, jsonString] of Object.entries(filters)) {
        const parsedArray = JSON.parse(jsonString);
        if (Array.isArray(parsedArray) && parsedArray.every(item => typeof item === 'object' && item !== null)) {
            filtersMap.set(key, parsedArray);
        }
    }
    return filtersMap;
}

