import { Dialog } from "@mui/material";
import OrderFormBase from "./OrderFormBase";
import { accessKeys, hasAccess } from "../../utils/userAccess";

// This just a wrapper, since the OrderForm will be used in different scenarios
export default function OrderFormDialog({ onClose, ...props }) {
  return (
    <Dialog
      maxWidth={false}
      scroll="paper"
      open={true}
      onClose={onClose}
      className={
        hasAccess(accessKeys.isMASystem)
          ? "bigCourseDialog"
          : "mediumCourseDialog"
      }
    >
      <OrderFormBase onClose={onClose} {...props} />
    </Dialog>
  );
}
