import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useEffect, useState } from "react";
import Preloader from "../Preloader";
import { dataGridLang } from '../MemToolBar';
import Translate from "../../utils/Translate";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../utils/Api";
import { avatarColumn, textColumn, dateColumn, booleanColumn, StatisticsGridToolBar } from "./StatisticsCommon";
import { CustomNoRowsOverlay } from '../../utils/StyledGridOverlay';
import BuildIcon from "@mui/icons-material/Build";
import PersonIcon from "@mui/icons-material/Person";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PhoneIcon from "@mui/icons-material/Phone";
import NumbersIcon from "@mui/icons-material/Numbers";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import EmailIcon from "@mui/icons-material/Email";
import CheckIcon from "@mui/icons-material/Check";
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDateWithMonthOffSet } from '../../utils/dateTimeFormat';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import TagIcon from '@mui/icons-material/Tag';
import FlagIcon from "@mui/icons-material/Flag";
import StarIcon from '@mui/icons-material/Star';
import MyActorSkillFilters from "./MyFilters/MyActorSkillFilters";

const ActorSkillsStatistics = () => {
    const colNames = {
        dataId: 'dataId',
        studentActorId: 'studentActorId',
        studentName: 'studentName',
        studentOrgNo: 'studentOrgNo',
        studentExternalActorNo: 'studentExternalActorNo',
        studentNationalityLabel: 'studentNationalityLabel',
        studentEmail: 'studentEmail',
        studentMobile: 'studentMobile',
        skillCode: 'skillCode',
        skillDesc: 'skillDesc',
        skillQty: 'skillQty',
        hasPassed: 'hasPassed',
        skillDate: 'skillDate',
        studentAdr: 'studentAdr',
        studentPincode: 'studentPincode',
        studentCity: 'studentCity',
        studentActorPictureUrl: 'studentActorPictureUrl'
    };

    const columns = [
        // textColumn(colNames.dataId, null, 'dataId'),
        // textColumn(colNames.studentActorId, null, 'studentActorId'),
        avatarColumn(colNames.studentName, null, colNames.studentActorPictureUrl, <PersonIcon />, 'Name'),
        textColumn(colNames.studentOrgNo, <FingerprintIcon />, 'PersonalNo'),
        textColumn(colNames.studentExternalActorNo, <TagIcon />, 'EmployeeNo'),
        textColumn(colNames.studentNationalityLabel, <FlagIcon />, 'Nationality'),
        textColumn(colNames.studentEmail, <EmailIcon />, 'Email'),
        textColumn(colNames.studentMobile, <PhoneIcon />, 'MobilePhone'),
        textColumn(colNames.skillCode, <NumbersIcon />, 'ShortCode'),
        textColumn(colNames.skillDesc, <BuildIcon />, 'Competence'),
        //textColumn(colNames.skillQty, null, 'skillQty'), // alltid 1
        booleanColumn(colNames.hasPassed, <CheckIcon />, 'Approved'),
        dateColumn(colNames.skillDate, <EventAvailableIcon />, 'Date'),
        textColumn(colNames.studentAdr, null, 'Address'),
        textColumn(colNames.studentPincode, null, 'ZipCode'),
        textColumn(colNames.studentCity, null, 'City')
    ];

    const initialHiddenColumns = [
        //colNames.studentName,
        colNames.studentOrgNo,
        colNames.studentEmail,
        colNames.studentMobile,
        colNames.studentExternalActorNo,
        colNames.studentNationalityLabel,
        //colNames.skillCode,
        //colNames.skillDesc,
        colNames.skillQty,
        //colNames.hasPassed,
        //colNames.skillDate,
        colNames.studentAdr,
        colNames.studentPincode,
        colNames.studentCity,
        // colNames.studentActorPictureUrl,
    ];

    const localizedTextsMap = dataGridLang();
    const initialGridState = {
        sorting: { sortModel: [{ field: colNames.skillDate, sort: 'desc' }] }, // TODO
        columns: {
            columnVisibilityModel: initialHiddenColumns.reduce((ac, a) => ({ ...ac, [a]: false }), {})
        }
    };

    let i = 0;
    const navigate = useNavigate();
    const [licenses, setLicenses] = useState();
    const smallScreen = useMediaQuery('(max-width:700px)');
    const [dataFetched, setDataFetched] = useState(false);
    const api = useApi();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [stdFilters, setStdFilters] = useState(["SelectFilter", "SkillsLastMonth"]);
    const [selectedStdFilter, setSelectedStdFilter] = useState("SelectFilter");
    const [filt, setFilt] = useState({
        items: []
    });

    const [openMyFilters, setOpenMyFilters] = useState(false);

    useEffect(async () => {
        let data = await api.fetch(`${process.env.REACT_APP_MAIN_URL}actorskills/report`);
        data = data.isSuccessful === false ? [] : data;

        data.forEach((element, index) => {
            element.id = index;
            element.studentNationalityLabel = element.studentNationality ?
                Translate.getCountryName(element.studentNationality) :
                null;
        });
        setLicenses(data);
        setDataFetched(true);
    }, [api]);

    const navigateToActor = (actorId) => {
        navigate(`/dashboard/person/${actorId}`, { replace: false });
    };

    function handleStdFilterChange(event) {
        setSelectedStdFilter(event.target.value);
        setStdFilter(event.target.value);
    }

    function setStdFilter(filterName) {
        switch (filterName) {

            case "SelectFilter":
                setFilt({ items: [] });
                break;

            case "SkillsLastMonth":
                setFilt({
                    ...filt,
                    items:
                        [
                            {
                                id: 1,
                                field: "skillDate",
                                operator: "onOrAfter",
                                value: formatDateWithMonthOffSet(new Date(), -1),
                                filterName: "SkillsLastMonth"
                            }
                        ]
                });
                break;

            default:
                return;
        }
    }

    function clearFilters() {
        setFilt({ items: [] });
        setSelectedStdFilter("SelectFilter");
    }

    function handleFilterModelChange(newFilterModel, x) {
        if (x.reason === "restoreState") {
            return;
        }

        if (x.reason === "changeLogicOperator") {
            setFilt({
                ...filt,
                logicOperator: newFilterModel.logicOperator
            });
            return;
        }

        const newFilt = { items: newFilterModel.items, logicOperator: newFilterModel.logicOperator };
        setFilt(newFilt);

        var stdFilterExistsInFilters = newFilt.items.findIndex(e => e.filterName === selectedStdFilter)
        if (stdFilterExistsInFilters === -1) {
            setSelectedStdFilter("SelectFilter");
        }
    }

    return <div style={{ height: "70vh", /* border:"1px solid blue" */ }}> {/* TODO: är 70vh bra? */}
        {!dataFetched && <Preloader invisible={true} />}
        {dataFetched &&

            <>
                <Grid container justifyContent="flex-start" sx={{ ml: 1 }}>

                    <Tooltip title={<div style={{ whiteSpace: 'pre-line' }}>{Translate.get('SkillStatisticsInfo')}</div>}>
                        <IconButton>
                            <InfoIcon color="primary" />
                        </IconButton>
                    </Tooltip>

                    <FormControl sx={{ minWidth: "5em" }}>
                        <InputLabel id="std-filter">{Translate.get("StdFilter") ?? " "}</InputLabel>
                        <Select
                            sx={{ maxWidth: smallScreen ? "10em" : "100%" }}
                            labelId="std-filter"
                            id="std-filter"
                            name="SkillsLastMonthFilter"
                            value={selectedStdFilter}
                            label={Translate.get("StdFilter") ?? " "}
                            onChange={handleStdFilterChange}
                        >
                            {stdFilters.map((element) =>
                                <MenuItem key={element} value={element} name={element}>{Translate.get(element)}</MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    <Button
                        onClick={() => clearFilters()}
                        variant="outlined"
                        disabled={filt.items?.length === 0}
                        startIcon={<DeleteIcon />}
                        sx={{ ml: "1em" }}
                        size="xlarge"

                    >
                        {Translate.get("Purge")}
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => setOpenMyFilters(true)}
                        startIcon={<StarIcon />}
                        style={{ marginLeft: "1em" }}
                    >
                        {Translate.get("MyFilters")}
                    </Button>
                    <MyActorSkillFilters
                        open={openMyFilters}
                        onClose={() => setOpenMyFilters(false)}
                        data={licenses}
                        setFilter={setFilt}
                    />
                </Grid>

                <DataGridPremium
                    getRowId={row => row.id}
                    rows={licenses}
                    filterModel={filt}
                    onFilterModelChange={(newFilterModel, x) => handleFilterModelChange(newFilterModel, x)}
                    columns={columns}
                    disableColumnMenu={true}
                    onRowClick={(params) => navigateToActor(params.row.studentActorId)}
                    pageSizeOptions={[10, 50, 100]}
                    pagination={true}
                    localeText={localizedTextsMap}
                    components={{
                        Toolbar: () => StatisticsGridToolBar("actorSkillsStatistics", "Competences"),
                        NoRowsOverlay: CustomNoRowsOverlay(Translate.get('EmptyHere'))
                    }}
                    density='comfortable'
                    disableDensitySelector={true}
                    initialState={initialGridState}
                    disableRowSelectionOnClick
                // onExcelExportStateChange={(newState) => {
                //     setDataFetched(newState !== "pending");
                // }}
                />
            </>
        }
    </div>
};

export default ActorSkillsStatistics;